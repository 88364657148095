import { CenterFocusStrong } from "@material-ui/icons";

const Styles = theme => ({
    form: {
        width: '100%',
        maxWidth: '1232px',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column'
        }
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px 0px 0px 0px',
        [theme.breakpoints.between('0', '600')]: {
            justifyContent: 'center',
        }
    },
    row: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            width: '100%'
        }
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    container: {
        width: '100%',
        maxWidth: '967px',
        display: 'flex',
    },
    results: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        minHeight: '150px',
        maxHeight: '300px',
        padding: '14px',
        borderRadius: '10px',
        border: '1px solid rgba(0, 0, 0, 0.20)',
        overflowY: 'scroll',
        [theme.breakpoints.between('0', '600')]: {
            maxHeight: '170px'
        },
    },
    column: {
        flex: '1',
        width: '100%',
        minWidth: '300px',
        padding: '0px 7px',
    },
    progress: {
        color: '#B0191A'
    },
    button: {
        width: 'auto !important',
        margin: '7px 0px 7px 7px !important',
        [theme.breakpoints.between('0', '600')]: {
            width: '100% !important',
        }
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
    dropdown: {
        width: '200px',
        marginLeft: '10px',
        "$ .MuiSelect-select": {
            backgroundColor: 'red'
        },
        [theme.breakpoints.between('0', '600')]: {
            width: '100%',
            marginLeft: '0px',
        }
    },
    dropdownSelect: {
        padding: '14px',
        backgroundColor: 'transparent',
    },
    progressContainer: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    label: {
        width: '100%',
        height: 'auto',
        fontSize: '27px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    anchor: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px 10px',
        padding: '0px',
        color: '#B0191A',
        cursor: 'pointer'
    },
})

export default Styles;