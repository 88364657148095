import React, { useContext, useEffect, useState } from 'react';
import { Grid, FormControl, Select, InputLabel, IconButton, Button} from '@material-ui/core';
import { ArrowBackIosOutlined, AddCircleRounded, DeleteForeverRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { usePopupOverlay } from '../../../../components/popup-overlay';
import * as Utils from '../../../../service/utils/functions';
import { ServiceContext } from '../../../../context/services/service-context';
import Styles from './style.js';


const useStyles = makeStyles(Styles);


const AssociateDemo = () => {
    
    const classes = useStyles();
    const history = useHistory();
    const { enterpriseId } = useParams();
    const { Enterprises } = useContext(ServiceContext);
    const { t, i18n } = useTranslation();
    const { triggerPopupOverlay } = usePopupOverlay();
    const [allDemos, setAllDemos] = useState([])
    const [availableDemos, setAvailableDemos] = useState([])
    const [demosToAssociate, setDemosToAssociate] = useState([])
    const [selectedDemo, setSelectedDemo] = useState(null)


    

    const onChangeDemoHandler = (id) => {
        const _demo = availableDemos.find(demo => demo.id === id)
        setSelectedDemo(_demo)
    }

    const onAddDemoHandler = () => {
        if (!demosToAssociate.includes(selectedDemo)) {
            setDemosToAssociate([...demosToAssociate, selectedDemo])
        }
    }

    const onDeleteDemoHandler = (data) => {
        const demos = demosToAssociate.filter(demo => demo.id !== data.id)
        setDemosToAssociate([...demos])
    }

    const onAssociateDemosHandler = async () => {
        try {
            await Promise.all(
                await demosToAssociate.map(async (demo, indexDemo) => {
                    const postdata = {
                        enterpriseId: enterpriseId,
                        demoId: demo.id
                    }
                    await Enterprises.associateDemoToEnterprise(postdata)
                    history.push("/enterprises/list");
                })
            )
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        const getDemo = async () => {
            try {
                const resp = await Enterprises.getAvailableDemosForEnterprise({ id: enterpriseId })
                const data = resp.data.getAvailableDemosForEnterprise
                setAllDemos(data)
                setAvailableDemos(data)
            } catch (error) {

            }
        }
        getDemo()
    }, [enterpriseId])

  
    return (
      
           <div className={classes.root}>
            <div className={classes.title}>{t('enterprises-page-title')}</div>
            <div className={classes.label}>{Utils.getTextLanguage('enterprie', i18n.language)}</div>
            <br />
            <div className={classes.header} onClick={() => { history.push('/enterprises/list') }}>
                <ArrowBackIosOutlined className={classes.icons} />
                <p>{t('action-btn-label-back')}</p>
            </div>
            <br />
            <div className={classes.grid}>
                {
                    <Grid container spacing={4}>
                        <Grid item xs={11}>
                            <FormControl variant="outlined" style={{ margin: '0px 0px', width: '100%' }} >
                                <InputLabel>{t('enterprise-available-demo-associate')}</InputLabel>
                                <Select
                                    native
                                    label={t('enterprise-available-demo-associate')}
                                    inputField={{
                                        name: t('demo'),
                                        id: 'demo',
                                    }}
                                    onChange={event => onChangeDemoHandler(event.target.value)}
                                >
                                    <option value={null} />
                                    {availableDemos.map(demo =>
                                        <option key={demo.id} value={demo.id}>
                                            {Utils.getTextLanguage(demo?.title, i18n.language)}
                                        </option>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            {selectedDemo && (
                                <IconButton onClick={() => onAddDemoHandler()}>
                                    <AddCircleRounded />
                                </IconButton>
                            )}
                        </Grid>
                        {demosToAssociate && demosToAssociate?.length > 0 && (
                            <Grid item xs>
                                <div className={classes.boxDemosToAssociate}>
                                    {demosToAssociate?.map(demo => (
                                        <div className={classes.rowDemoToAssociate}>
                                            <p>{Utils.getTextLanguage(demo?.title, i18n.language)}</p>
                                            <IconButton aria-label="delete" className={classes.margin} onClick={() => onDeleteDemoHandler(demo)}>
                                                <DeleteForeverRounded />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                                <Button variant="contained" color="primary" onClick={() => onAssociateDemosHandler()}>
                                    {t('enterprises-associate-demos-btn')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                }
            </div>
        </div>
    );
}

export default AssociateDemo;