import { Storage } from "aws-amplify";


export const uploadFile = async (fullPath, file, bucket) => {
    if (bucket) {
        return Storage.put(fullPath, file, {
            bucket: bucket
        })
    } else {
        return Storage.put(fullPath, file, { contentType: file.type })
    }
}

export const getFile = async (key, bucket) => {
    if (bucket) {
        return Storage.get(key, {
            bucket: bucket
        })
    } else {
        return Storage.get(key)
    }
}