import React, { useEffect, useState } from 'react';

import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import ConfirmationDeleteRender from './ConfirmationDeleteRender';
import Tags from '../tags';
import { usePopupOverlay } from '../popup-overlay';

import * as Utils from '../../service/utils/functions';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const UploadFilesContainer = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [attachments, setAttachments] = useState([]);
    const { triggerPopupOverlay } = usePopupOverlay();


    useEffect(() => {
        setAttachments(props.attachments);
    }, [props.attachments])

    const onAddAttachmentHandler = async () => {
        await props.actions.onCreate();
    }

    const onDeleteAttachmentHandler = async (id) => {
        triggerPopupOverlay(<ConfirmationDeleteRender description={t('assets-popup-delete-description')} />, t('assets-popup-delete-title'), async () => {
            await props.actions.onDelete(id);
        });
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}><p>{t('component-upload-file-container-label')}</p></div>
            <div className={classes.container}>
                {
                    attachments ?
                        attachments.length > 0 ?
                            attachments.map((prop, index) => {
                                if (prop)
                                    return (<Tags key={index} label={Utils.getTextLanguage(prop.title, i18n.language)}  icon={prop.type} action={async () => { await onDeleteAttachmentHandler(prop.id) }} />);
                            })
                            : <div className={classes.message}>{t('component-upload-file-container-message')}</div>
                    : <div className={classes.message}>{t('component-upload-file-container-message')}</div>
                }
            </div>
            <div className={classes.footer}>
                <div className={classes.action} onClick={onAddAttachmentHandler}>
                    <Add className={classes.icons} />
                    <p>{t('component-upload-file-container-anchor')}</p>
                </div>
            </div>
        </div>
    );
}
export default UploadFilesContainer;