const typography = (theme) => {
    return {
        h1: {
            color: '#000000',
            fontFamily: 'Open Sans',
            fontSize: '38px',
            lineHeight: 1.21
        },
        h2: {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: '23px',
            letterSpacing: '-0.24px',
            lineHeight: '42px'
        },
        title_h1: {
            fontSize: '40px',
            fontWeight: 'bold',
            color: theme?.font?.primary,
        },
        title_h1_reg: {
            fontSize: '40px',
            fontWeight: 'regular',
            color: theme?.font?.primary,
        },
        title_h2: {
            fontSize: '28px',
            fontWeight: 'bold',
            color: theme?.font?.primary,
        },
        subtitle: {
            color: '#000000',
            fontFamily: 'Open Sans',
            fontWeight: 800,
        },
        label: {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 'regular',
            color: '#000000',
            lineHeight: 1.25
        },
        body: {
            fontSize: '15px',
            fontWeight: 'regular',
            color: theme?.font?.primary,
        },
        anchor: {
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 'regular',
            color: theme?.font?.link,
            lineHeight: 1.25
        }
    }
}

export default typography;