
import Assets from '../assets';



const Styles = theme => ({
    root: {
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF'
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'Column',
        justifyContent: 'space-between',
    },
    toolbarContainer: {
        width: '100%',
        height: 'auto',
    },
    viewContainer: {
        width: 'calc(100% - 40)',
        height: '100%',
        display: 'flex',
        padding: '0px',
        [theme?.breakpoints?.between('0', '600')]: {
            width: 'calc(100% - 25)',
            display: 'initial',
            padding: '25px',
        },
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: 'scroll',
    },
    full: {
        backgroundImage: `url(${Assets.Images.cy_bg_aws_media_demos})`,
        backgroundSize: 'cover',
        backgroundColor: '#000',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        top: '0',
        zIndex: 1
    },
})

export default Styles;