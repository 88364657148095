import { CenterFocusStrong } from "@material-ui/icons";

const Styles = theme => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px 0px 0px 0px',
        [theme?.breakpoints?.between('0', '600')]: {
            justifyContent: 'center',
        }
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme?.breakpoints?.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        padding: '0px 7px',
    },
    progress: {
        color: '#B0191A'
    },
    button: {
        width: 'auto !important',
        margin: '7px !important',
        [theme?.breakpoints?.between('0', '600')]: {
            width: '100% !important',
        }
    },
    description: {
        flex: 1,
        padding: '15px 7px',
        fontWeight: '300'
    }
})

export default Styles;