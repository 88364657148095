import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Structer } from './config';
import Styles from './style';


const useStyles = makeStyles(Styles);

const EnterprisesView = ({ history }) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { viewId } = useParams();
    const [structer, setStructer] = useState({ index: 0, component: null });



    useEffect(() => {
        onLoadPage();
    }, [])

    const onLoadPage = () => {
        const viewIndex = Structer.sections.find(item => item.id == viewId);
        setStructer({ index: viewIndex ? viewIndex.index : 0, component: viewIndex ? viewIndex.component : Structer.sections[0].component });
    }

    const handleChange = (event, newValue) => {
        const viewIndex = Structer.sections.find(item => item.index == newValue);
        setStructer({ index: viewIndex.index, component: viewIndex.component });
        history.push(viewIndex.path);
    };





    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }


    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>{t(Structer.title)}</div>
            </div>
            <div className={classes.navbar}>
                {
                    Structer.sections.length > 0 ?
                        <Tabs
                            value={structer.index}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {
                                Structer.sections.map((prop, index) => {
                                    return (
                                        <Tab key={`tab-${index + Date.now()}`} label={t(prop.label)} icon={prop.icon} {...a11yProps(prop.index)} />
                                    )
                                })
                            }
                        </Tabs>
                        :
                        null
                }
            </div >
            <div className={classes.grid}>
                {
                    structer.component
                }
            </div>
        </div>
    );
}

export default withRouter(EnterprisesView);