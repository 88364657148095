import React from 'react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const CardPlaceholder = ({
    classes,
    data
}) => {

    const { i18n, t } = useTranslation();
    

    const onClickHandle = () => {
        window.open(`mailto:${t('card-placeholder-subtitle')}`);
    }

    
    return (
        <div className={classes.root} onClick={onClickHandle}>
            <div className={classes.header}>
                <div className="header-inner">
                    <h2>{t('card-placeholder-title')}</h2>
                    <h3>{t('card-placeholder-subtitle')}</h3>
                </div>
            </div>
            <div className={classes.imageBox}>
                <img src={data?.imageUrl} alt="" />  
            </div>
       </div>
    )
}



export default compose(
    withStyles(styles)
)(CardPlaceholder)
