import MuiTextField from './MuiTextField';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiDrawer from './MuiDrawer';
import MuiToolbar from './MuiToolbar';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';

const overrides = (theme) => {
    return {
        MuiTextField: MuiTextField(theme),
        MuiOutlinedInput: MuiOutlinedInput(theme),
        MuiButton: MuiButton(theme),
        MuiPaper: MuiPaper(theme),
        MuiDrawer: MuiDrawer(theme),
        MuiToolbar: MuiToolbar(theme),
        MuiTab: MuiTab(theme),
        MuiTabs: MuiTabs(theme)
    }
}

export default overrides;

