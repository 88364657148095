import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { CreateOutlined, DeleteOutlined, ExpandMore } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const CategoryRow = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [state, setState] = useState( { isLoading: false, expand: props.expand });

    const onEditHandler = async (data) => {
        await props.actions.onEdit(data);
    }

    const onDeleteHandler = async (data) => {
        setState({ ...state, isLoading: true });
        await props.actions.onDelete(data);
        setState({ ...state, isLoading: false });
    }

    const onExpandContent = () => {
        setState({ ...state, expand: !state.expand });
    }



    return (
        <div className={classes.root}>
            <div className={classes.header} style={{ display: props.hideHeader ? 'none' : 'flex' }}>
                <div className={classes.buttons} onClick={onExpandContent}>
                    <ExpandMore className={classes.icons_invert} style={{transform: state.expand ? 'rotateX(180deg)' : 'rotateX(0deg)'}}/>
                </div>
                <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={onExpandContent}>
                    <div className={classes.title}><p>{props?.title ? `${props?.title} (${props.numberItems > 0 ? props.numberItems : 0})` : `(${props.numberItems ? props.cnumberItems : 0})`}</p></div>
                    <div className={classes.line}></div>
                </div>
                <div className={classes.actions}>
                    <div className={classes.buttons_round} onClick={() => onEditHandler(props)} >
                        <CreateOutlined className={classes.icons} />
                    </div>
                    <div className={classes.buttons_round} onClick={() => onDeleteHandler(props)} >
                        <DeleteOutlined className={classes.icons} />
                    </div>
                </div>
            </div>
            <div className={classes.content} style={{ justifyContent: props.children?.length > 0 ? 'flex-start' : 'center', height: state.expand ? 'auto' : '0px', padding: state.expand ? '25px 0px' : '0px'}}>
                {
                    props.children?.length > 0 ? props.children : <span style={{height: '150px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>{t('component-category-row-message')}</span>
                }
            </div>
            <div className={classes.overlay} style={{ display: state.isLoading ? 'flex' : 'none' }}>
                <CircularProgress size={50} className={classes.progress} />
            </div>
        </div>
    );
}
export default CategoryRow;