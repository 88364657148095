import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import aws_export from './aws-exports';
import App from './App';
import './i18n';
import './index.css';

Amplify.configure(aws_export);
ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
