import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePopupOverlay } from '../..';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ConfirmationRender = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { close, callback } = usePopupOverlay();

    /**
    * Form submition handler when updated a Gallery category.
    */
    const onConfirmHandler = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            try {
                await callback();
                close();
            } catch (error) {
                throw error
            }
        },
    });

    return (
        <form onSubmit={onConfirmHandler.handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item sm>
                    <div className={classes.columnContainer}>
                        <div className={classes.description}>{props?.description}</div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.actions}>
                <Button type='submit' variant="contained" disableElevation disabled={onConfirmHandler.isSubmitting} classes={{ root: classes.button }}>{
                    onConfirmHandler.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('component-popup-sample-confirrmation-btn-confirm')
                }</Button>
                <Button onClick={close} variant="outlined" disableElevation disabled={onConfirmHandler.isSubmitting} classes={{ root: classes.button }}>{t('component-popup-sample-confirrmation-btn-cancel')}</Button>
            </div>
        </form>
    )
}

export default ConfirmationRender;