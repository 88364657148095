const MuiDrawer = (theme) => {
    return {
        paper: {
            height: 'auto',
            top: '0px',
            bottom: '0px',
            right: '0px',
            left: '0px',
            padding: '10px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            borderRadius: '0px 10px 10px 0px',
            boxShadow: '1px 1px 10px #EEEEEE',
            overflowY: 'visible'
        },
        paperAnchorDockedLeft: {
            borderRight: 'none'
        }
    }
};

export default MuiDrawer;
