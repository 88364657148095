import backgroundImage from './../../assets/images/cy-bg-aws-media-demos.jpg';

const classes = theme => ({
    root: {
        zIndex: 2,
        color: '#FFF',
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        }
    },
    container: {
        zIndex: 2,
        maxWidth: '1664px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column'
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
       header: {
            height: 'auto',
            padding: '20px 0px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: '60px',
            alignItems: 'center',
            [theme?.breakpoints?.down('sm')]: {
                marginBottom: '20px',
            }
    },
    titleBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& h1': {
            marginLeft: '77px',
        },
         [theme?.breakpoints?.down('sm')]: {
           '& h1': {
                marginLeft: '0',
                fontSize: '32px'
        },
        }
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.8)',
        top: '0',
        zIndex: 1
    },
      footer: {
        margin: '60px 0 60px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontSize: '12px',
             margin: '0px 0 6px'
        },
        [theme?.breakpoints?.down('sm')]: {
             padding: '15px 12px 40px',
        }
    },
    cycText: {
        fontFamily: 'Open Sans',
        color: '#d0212a',
        fontWeight: 'bold'
    },
     cycPrivacy: {
        fontFamily: 'Open Sans',
         color: '#000000',
         '& a': {
             color: '#000000',
             fontFamily: 'Open Sans',
             fontSize: '12px',
             textDecoration: 'none'
        }
    },
      cycWebsite: {
        fontFamily: 'Open Sans',
        color: '#000000'
    },
    descriptionBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        lineHeight: 1.25,
        color: '#000000',
        maxWidth: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
     }
})
export default classes;
