import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Assets from '../../Resources';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const AssetCard = (props) => {

    const classes = useStyles();

    const onClickHandler = () => {
        switch (props?.data?.result) {
            case 'ALL':
                props?.onClick(props?.data?.content);
                break;
            case 'FILTERED':
                let params = {
                    altText: props?.data?.content?.altText,
                    assetId: props?.data?.content?.assetId,
                    description: props?.data?.content?.description,
                    thumbnail: props?.data?.content?.thumbnail,
                    title: props?.data?.content?.title,
                    type: props?.data?.content?.type,
                    url: props?.data?.content?.url,
                }
                props?.onClick(params);
                break;
            default:
                props?.onClick(props?.data?.content);
                break;
        }
    }

    const getThumbnailImage = (data) => {
        if (data?.thumbnail) {
            return data?.thumbnail;
        } else if (data?.type === "IMAGE") {
            return data?.url;
        } else {
            return pickFileThumbnail(data?.type);
        }
    }

    const thumbnailFallback = (data) => {
        data.target.src = Assets.Images.error_image;
    }

    const pickFileThumbnail = (type) => {
        switch (type) {
            case 'DOCUMENT':
                return Assets.Images.doc_thumbnail;
            case 'VIDEO':
                return Assets.Images.video_thumbnail;
            case 'AUDIO':
                return Assets.Images.audio_thumbnail;
            case 'IMAGE':
                return Assets.Images.image_thumbnail;
            default:
                return Assets.Images.doc_thumbnail;
        }
    }

    return (
        <div className={classes.root} onClick={onClickHandler}>
            <div className={classes.thumbnail}>
                <img src={getThumbnailImage(props?.data?.content)} onError={thumbnailFallback} />
            </div>
            <div className={classes.info}>
                <div className={classes.title}><p>{props?.data?.content.title}</p></div>
                <div className={classes.subtitle}><p>{props?.data?.content.type}</p></div>
                <div className={classes.description}><p>{props?.data?.content?.createdOn?.split('T')[0]}</p></div>
            </div>
        </div>
    )
}

export default AssetCard;