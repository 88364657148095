import React, { useEffect, useState, createContext, useContext } from 'react';
import * as AuthService from '../../service/api/auth/';
import { useNotifications } from '../../components/notification-snackbar';
import * as Utils from '../../utils/functions';
import * as StorageService from '../../service/storage';


export const AuthContext = createContext();

const AuthContextProvider = (props) => {



    const { triggerNotification } = useNotifications()
    const [state, setState] = useState({
        isLoading: true,
        isAuthenticated: false,
        cognitoUser: null,
        attributes: null,
        userGroup: null,
        isBo: false,
        client: 'generic',
        theme: {},
        config: {}
    });

    useEffect(() => {
        onLoadPage()
    }, []);



    const getRoles = async (data) => {
        let client = data?.client
        if (!client) return null
        let path = `${process.env.PUBLIC_URL}/config/config_${client}_roles.json`
        return await fetch(path)
            .then((response) => response.json())
            .then(json => {
                return json
            }).catch(error => {
                console.log(error);
            })


    }


    const onLoadPage = async () => {

        const params = getQueryParams();
        const lastClient = await StorageService.getClientApp()

        let config = {};
        let theme = {};
        let client = params?.client ? params.client : null
        let isBo = false;
        let onSession = !!lastClient
        let stateUpdated = { ...state }

        //console.log('onSession = ', onSession)
        //console.log('client = ', client)

        if (onSession) { // User is on current session
            //console.log('********* onSession ************')

            if (!client) {
                client = lastClient
                isBo = client !== 'generic'
                config = await getConfigFile(client);
                theme = config?.theme
                stateUpdated = { ...stateUpdated, client: client, config: config, theme: theme, isBo: isBo }
                currentUserPoolUser(stateUpdated);
                setState(stateUpdated)
            } else {
                if (client === lastClient) {
                    //console.log('User on session does not change client')
                    isBo = client !== 'generic'
                    config = await getConfigFile(client);
                    theme = config?.theme
                    stateUpdated = { ...stateUpdated, client: client, config: config, theme: theme, isBo: isBo }
                    currentUserPoolUser(stateUpdated);
                    setState(stateUpdated)

                } else { // User on session change client
                    //console.log('User on session change client')
                    isBo = client !== 'generic'
                    config = await getConfigFile(client);
                    theme = config?.theme
                    stateUpdated = { ...stateUpdated, client: client, config: config, theme: theme, isBo: isBo }
                    await StorageService.setClientApp(client)
                    await AuthService.signOut();
                    Utils.deleteAllCookies();
                    setState({ ...stateUpdated, isLoading: false, isAuthenticated: false, cognitoUser: null, attributes: null });
                }
            }

            //console.log('********* onSession END ************')


        } else {
            //console.log('***** NO SESSION *******')

            client = client ? client : 'generic'
            isBo = client !== 'generic'
            config = await getConfigFile(client);
            theme = config?.theme
            stateUpdated = { ...stateUpdated, client: client, config: config, theme: theme, isBo: isBo }
            await StorageService.setClientApp(client)
            await AuthService.signOut();
            Utils.deleteAllCookies();
            setState({ ...stateUpdated, isLoading: false, isAuthenticated: false, cognitoUser: null, attributes: null });
        }

        //console.log('***** OnLoadPage End *******')
    }


    const getConfigFile = async (client = null) => {
        let path = client ? `${process.env.PUBLIC_URL}/config/config_${client}.json` : `${process.env.PUBLIC_URL}/config/config_generic.json`
        return await fetch(path)
            .then((response) => response.json())
            .then(json => {
                return json
            }).catch(error => {
                console.log(error);
            })
    }

    const getQueryParams = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return Object.fromEntries(urlSearchParams.entries());
    }






    /**
     * Sign to the user account
     * @param {*} username email used to register the account.
     * @param {*} password password to access de account.
     * @returns 
     */
    const signin = async (username, password) => {
        try {
            const result = await AuthService.signIn(username, password);
            const { challengeName } = result
            if (challengeName) {
                setState({ ...state, isLoading: false, isAuthenticated: false, cognitoUser: result, attributes: null });
            } else {
                Utils.setCookie("jwtToken", result?.signInUserSession?.accessToken?.jwtToken, 1);
                const Listusergroups = result?.signInUserSession.accessToken.payload['cognito:groups']
                const rolesConfig = await getRoles({ ...state })
                const usergroupsAllowed = rolesConfig?.usergroups
                let isIncluded = false
                if (usergroupsAllowed.includes('*')) {
                    isIncluded = true
                } else {
                    isIncluded = usergroupsAllowed.some(value => Listusergroups.includes(value))
                }
                if (isIncluded) {
                    setState({ ...state, isLoading: false, isAuthenticated: true, cognitoUser: result, attributes: result.attributes, userGroup: Listusergroups });
                } else {
                    signout()
                }
            }
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-signin-NotAuthorizedException-message", "notifications-signin-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const signUp = async (data) => {
        try {
            const result = await AuthService.signUp(data);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    break;
                case 'NotAuthorizedException':
                    break;
                case 'UserNotFoundException':
                    break;
                default:
                    break;

            }
        }
    }

    const confirmSignUp = async (username, code) => {
        try {
            const result = await AuthService.confirmSignUp(username, code);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    break;
                case 'NotAuthorizedException':
                    break;
                case 'UserNotFoundException':
                    break;
                default:
                    break;
            }
        }
    }


    /**
     * Signout the session of the currect signin account.
     */
    const signout = async () => {
        await AuthService.signOut();
        Utils.deleteAllCookies();
        setState({ ...state, isLoading: false, isAuthenticated: false, cognitoUser: null, attributes: null });
    };


    /**
     * Request password recover to access the account
     * @param {*} username email used to register the account.
     * @returns 
     */
    const forgotPassword = async (username) => {
        try {
            const result = await AuthService.forgotPassword(username);
            if (result) {
                triggerNotification("success", "notifications-recoverpassword-emailsent-message", "notifications-recoverpassword-emailsent-title");
            }
            setState({ ...state, isLoading: false, isAuthenticated: false, cognitoUser: result, attributes: null });
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'InvalidParameterException':
                    triggerNotification("warning", "notifications-forgotpassword-InvalidParameterException-message", "notifications-forgotpassword-InvalidParameterException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    /**
     * Password recover confirmation
     * @param {*} username email used to register the account.
     * @param {*} code verify code received via email to confirm the recover.
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const forgotPasswordSubmit = async (username, code, password, newPassword) => {
        try {
            if (password !== newPassword) {
                triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                return;
            }
            const result = await AuthService.forgotPasswordSubmit(username, code, newPassword);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'InvalidParameterException':
                    triggerNotification("warning", "notifications-forgotpassword-InvalidParameterException-message", "notifications-forgotpassword-InvalidParameterException-title");
                    break;
                case 'ExpiredCodeException':
                    triggerNotification("warning", "notifications-forgotpassword-ExpiredCodeException-message", "notifications-forgotpassword-ExpiredCodeException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    /**
     * Change currrent password to access the account
     * @param {*} oldPassword old password used to access the account.
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const changePassword = async (oldPassword, newPassword) => {
        try {
            const result = await AuthService.changePassword(state.cognitoUser, oldPassword, newPassword);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-changepassword-NotAuthorizedException-message", "notifications-changepassword-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                case 'LimitExceededException':
                    triggerNotification("error", "notifications-default-LimitExceededException-message", "notifications-default-LimitExceededException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    /**
     * First login replace password
     * @param {*} newPassword new password to access the account.
     * @returns 
     */
    const completeNewPassword = async (password, newPassword) => {
        try {
            if (password !== newPassword) {
                triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                return;
            }
            const result = await AuthService.completeNewPassword(state.cognitoUser, newPassword);
            setState({ ...state, isLoading: false, isAuthenticated: false, cognitoUser: null, attributes: null });
            return result;

        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    /**
     * Get the current user pool user information, if the session is valid.
     */
    const currentUserPoolUser = async (data) => {

        try {
            let result = await AuthService.currentUserPoolUser()
            if (result) {
                Utils.setCookie("jwtToken", result?.signInUserSession?.accessToken?.jwtToken, 1);
                const Listusergroups = result?.signInUserSession.accessToken.payload['cognito:groups']
                const rolesConfig = await getRoles(data)
                const usergroupsAllowed = rolesConfig?.usergroups
                let isIncluded = false
                if (usergroupsAllowed.includes('*')) {
                    isIncluded = true
                } else {
                    isIncluded = usergroupsAllowed.some(value => Listusergroups.includes(value))
                }

                if (isIncluded) {
                    setState({ ...data, isLoading: false, isAuthenticated: true, cognitoUser: result, attributes: result.attributes, userGroup: Listusergroups });
                } else {
                    signout()
                }
            }
        } catch (error) {
            switch (error) {
                case 'No current user':
                    setState({ ...data, isAuthenticated: false, isLoading: false, cognitoUser: null, attributes: null });
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    setState({ ...data, isAuthenticated: false, isLoading: false, cognitoUser: null, attributes: null });
                    break;
            }
        }
        //console.log('********* currentUserPoolUser end ***************')

    };

    /**
     * Update user account attributes
     * @param {object} attributes object with diferente user attributes.
     * @returns 
     */
    const updateUserAttributes = async (attributes) => {
        try {
            const updatedAttributes = updateToKeyCustomAttribute(attributes);
            const result = await AuthService.updateUserAttributes(state.cognitoUser, updatedAttributes);
            return result;
        } catch (error) {
            const errorCode = error.code
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                case 'NotAuthorizedException':
                    triggerNotification("error", "notifications-default-NotAuthorizedException-message", "notifications-default-NotAuthorizedException-title");
                    break;
                case 'UserNotFoundException':
                    triggerNotification("error", "notifications-default-UserNotFoundException-message", "notifications-default-UserNotFoundException-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    return (
        <AuthContext.Provider value={{ ...state, signin, signout, signUp, confirmSignUp, forgotPassword, forgotPasswordSubmit, changePassword, completeNewPassword, updateUserAttributes }}>
            {props.children}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider;



//UTILS
function updateToKeyCustomAttribute(attributes) {
    let array = {}
    Object.entries(attributes).forEach(([key, value]) => {
        array = {
            ...array,
            [`custom:${key}`]: value.toString()
        }
    });
    return array;
}