import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, TextField, Button, Avatar, CircularProgress } from '@material-ui/core';
import { PersonRounded, EmailRounded, PhoneRounded, HomeRounded, LanguageRounded, LocationCityRounded, LocationOnRounded } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { AuthContext } from '../../../../context/auth/auth-context';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const Information = () => {

    const classes = useStyles();
    const { attributes, updateUserAttributes } = useContext(AuthContext);
    const { t } = useTranslation();

    const changeUserInformation = useFormik({
        initialValues: {
            name: attributes.name ? attributes.name : attributes.email.split('@')[0],
            email: attributes.email,
            phone: attributes['custom:phone'],
            address: attributes['custom:address'],
            country: attributes['custom:country'],
            region: attributes['custom:region'],
            city: attributes['custom:city'],
            zipcode: attributes['custom:zipcode'],
        },
        onSubmit: async (values) => {
            try {
                delete values.email;
                const response = await updateUserAttributes(values);
            } catch (error) {
                throw error
            }
        },
    });

    const changeUserPicture = useFormik({
        initialValues: {
            picture: '',
        },
        onSubmit: async (values) => {
            try {
            } catch (error) {
                throw error
            }
        },
    });

    return (
        <Grid container spacing={4}>
            <Grid item xs>
                <Paper elevation={0} className={classes.paper}>
                    <form onSubmit={changeUserPicture.handleSubmit} className={classes.form}>
                        <Avatar className={classes.userPhoto}>J</Avatar>
                        <div className={classes.userName}>{attributes.name ? attributes.name : attributes.email.split('@')[0]}</div>
                        <div className={classes.userRole}>Administrator</div>
                        <Button type='submit' variant="contained" disableElevation disabled={changeUserPicture.isSubmitting} classes={{ root: classes.button }}>{
                            changeUserPicture.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('account-information-photo-btn')
                        }</Button>
                    </form>
                </Paper>
            </Grid>
            <Grid item lg={8}>
                <Paper elevation={0} className={classes.paper}>
                    <form onSubmit={changeUserInformation.handleSubmit} className={classes.form}>
                        <div className={classes.columnContainer}>
                            <div className={classes.column}>
                                <TextField
                                    id="name"
                                    type='text'
                                    label={t('account-information-input-name')}
                                    placeholder={t('account-information-input-name')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <PersonRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.name}
                                    required
                                />
                                <TextField
                                    id="phone"
                                    type='number'
                                    label={t('account-information-input-phone')}
                                    placeholder={t('account-information-input-phone')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <PhoneRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.phone}
                                />
                                <TextField
                                    id="country"
                                    type='text'
                                    label={t('account-information-input-country')}
                                    placeholder={t('account-information-input-country')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <LanguageRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.country}
                                />
                                <TextField
                                    id="city"
                                    type='text'
                                    label={t('account-information-input-city')}
                                    placeholder={t('account-information-input-city')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <LocationCityRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.city}
                                />
                            </div>
                            <div className={classes.column}>
                                <TextField
                                    id="email"
                                    type='email'
                                    label={t('account-information-input-email')}
                                    placeholder={t('account-information-input-email')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.email}
                                    disabled
                                />
                                <TextField
                                    id="address"
                                    type='text'
                                    label={t('account-information-input-address')}
                                    placeholder={t('account-information-input-address')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <HomeRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.address}
                                />
                                <TextField
                                    id="region"
                                    type='text'
                                    label={t('account-information-input-region')}
                                    placeholder={t('account-information-input-region')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <LocationCityRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.region}
                                />
                                <TextField
                                    id="zipcode"
                                    type='text'
                                    label={t('account-information-input-zipcode')}
                                    placeholder={t('account-information-input-zipcode')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <LocationOnRounded className={classes.icons} /> }}
                                    onChange={changeUserInformation.handleChange}
                                    defaultValue={changeUserInformation.initialValues.zipcode}
                                />
                            </div>
                        </div>
                        <Button type='submit' variant="contained" disableElevation disabled={changeUserInformation.isSubmitting} classes={{ root: classes.button }}>{
                            changeUserInformation.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('account-information-info-btn')
                        }</Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Information;