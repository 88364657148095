const Styles = theme => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        [theme.breakpoints.between('0', '600')]: {
            minWidth: 'auto',
        },
        padding: '0px 7px',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        position: 'relative'
    },
    userPhoto: {
        width: '200px',
        height: '200px',
        fontSize: '80px',
        margin: '0px 20px 20px 20px'
    },
    userName: {
        ...theme.typography.title_h2,
    },
    userRole: {
        padding: '4px 0px',
        ...theme.typography.subtitle_h1,
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
    iconsTag: {
        fontSize: '20px',
        color: '#FFFFFF',
    },
    button: {
        margin: '25px 0px 0px auto !important',
    },
    progress: {
        color: '#B0191A'
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
    checkbox: {
        color: "brown",
    },
    dropdown: {
        minWidth: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        "$ .MuiSelect-select": {
            backgroundColor: 'red'
        }
    },
    dropdownSelect: {
        backgroundColor: 'transparent',
        "&:focus":{
            backgroundColor: 'transparent',
        }
    },
    inputLanguage: {
        width: '130px', 
        height: '50px', 
        padding: '0px 5px', 
        position: 'absolute', 
        top: '-50px', 
        right: '15px', 
        borderRadius: '7px 7px 0px 0px', 
        backgroundColor: '#B0191A'
    },
    switchBox: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    boxImage: {
        height: 280,
        width: '100%',
        paddingLeft: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 10,
            background: 'rgba(219, 67, 50, 1)'
        }
    },
    fileDropzoneBox: {
        width: '100%'
    },
    labelUploadImage: {
        margin: '10px 0 20px',
        cursor: 'pointer'
    },
})

export default Styles;