import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import Styles from './style';

const useStyles = makeStyles(Styles);

const UserManagement = () => {

    const classes = useStyles();
    const { t } = useTranslation();


    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {t('users-management')}
            </div>
            <div className={classes.grid}>
                <a href="https://eu-west-1.admin.amplifyapp.com/admin/login?appId=duuwor595se71&backendEnvironmentName=dev" target="_blank"  >
                    {t('users-management-link')}
                </a>
            </div>
        </div>
    );
}

export default withRouter(UserManagement);