import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, TextField, Button, IconButton, InputAdornment, CircularProgress } from '@material-ui/core';
import { LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { AuthContext } from '../../../../context/auth/auth-context';
import { useNotifications } from '../../../../components/notification-snackbar';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ChangePassword = () => {

    const classes = useStyles();
    const { changePassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const { t } = useTranslation();
    const [values, setValues] = useState({ showOldPassword: false, showNewPassword: false, showConfirmNewPassword: false });

    const changePasswordFormik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        },
        onSubmit: async (values) => {
            try {
                if (values.newPassword !== values.confirmNewPassword) {
                    triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                    return;
                }
                const response = await changePassword(values.oldPassword, values.confirmNewPassword);
            } catch (error) {
                throw error
            }
        },
    });

    const handleClickShowOldPassword = () => {
        setValues({ ...values, showOldPassword: !values.showOldPassword });
    };
    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };
    const handleClickShowConfirmNewPassword = () => {
        setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs>
                <Paper elevation={0} className={classes.paper}>
                    <form onSubmit={changePasswordFormik.handleSubmit} className={classes.form}>
                        <TextField
                            id="oldPassword"
                            type={values.showOldPassword ? 'text' : 'password'}
                            placeholder={t('account-changepassword-input-oldpassword')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowOldPassword}
                                        edge="end">
                                        {values.showOldPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={changePasswordFormik.handleChange}
                            required
                        />
                        <TextField
                            id="newPassword"
                            type={values.showNewPassword ? 'text' : 'password'}
                            placeholder={t('account-changepassword-input-newpassword')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        edge="end">
                                        {values.showNewPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={changePasswordFormik.handleChange}
                            required
                        />
                        <TextField
                            id="confirmNewPassword"
                            type={values.showConfirmNewPassword ? 'text' : 'password'}
                            placeholder={t('account-changepassword-input-confnewpassword')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmNewPassword}
                                        edge="end">
                                        {values.showConfirmNewPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={changePasswordFormik.handleChange}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={changePasswordFormik.isSubmitting} classes={{ root: classes.button}}>{
                            changePasswordFormik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('account-changepassword-btn')
                        }</Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ChangePassword;