
import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Button } from '@material-ui/core';
import { BlockOutlined } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import Assets from '../../../assets';
import Styles from './style';


const useStyles = makeStyles(Styles);

const Unauthorized = () => {

    const { config } = useContext(AuthContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                <LanguageSelector type="app" />
            </Container>
            <Container className={classes.content}>
                {/* <div className={classes.logoContainer}>
                    <img src={`${process.env.PUBLIC_URL}/config/${config?.logo?.full}`} />
                </div> */}

                <div className={classes.contentHeader}>
                    <div className={classes.logo}>
                        <img src={`${process.env.PUBLIC_URL}/config/${config?.logo?.full}`} />
                    </div>
                    {/* <div className={classes.contentHeaderTitle}>{config?.name ? config?.name : null}</div> */}
                </div>
                <div className={classes.paper} elevation={0}>
                    <div style={{width: '100%', display:'flex', alignContent: 'center', justifyContent: 'center'}}>
                        <BlockOutlined className={classes.icons} />
                        <div className={classes.title}>{t('unauthorized-text-title')}</div>
                    </div>
                    <div className={classes.subtitle}>{t('unauthorized-text-subtitle')}</div>
                    <br />
                    <Button onClick={() => history.push('/auth/login')} variant="contained" disableElevation>{t('unauthorized-btn-label')}</Button>
                </div>
            </Container>
            <Container className={classes.footer} maxWidth="xl">
                <div className={classes.subtext}>Powered by <img src={Assets.Images.cyc_logo_parcial} alt="Cycloid Logo" /></div>
                <span className={classes.subtext}>{t('signin-footer-text-subtext-1')}<a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchor} target="_blank">{t('signin-footer-text-subtext-2')}</a></span>
            </Container>
        </Container>
    );
}

export default Unauthorized;