import React, { createContext } from 'react';
import { DemosService } from './services/demos/demos-service';
import { EnterprisesService } from './services/enterprises/enterprises-service';


export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

    const Demos = DemosService();
    const Enterprises = EnterprisesService();




    return (
        <ServiceContext.Provider value={{ Demos, Enterprises }}>
            { props.children}
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider;