import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { AttachFileRounded, ImageOutlined,  CloseOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const Tags = (props) => {

    const classes = useStyles();
    const [state, setState] = useState({ isLoading: false })

    const onActionHandler = async () => {
        setState({ ...state, isLoading: true });
        await props.action();
        setState({ ...state, isLoading: false });
    }

    return (
        <div className={classes.root}>
            <div className={classes.icon}>{iconPicker(props.icon, classes.iconTag)}</div>
            <p>{props.label}</p>
            <div className={classes.action} onClick={onActionHandler}><CloseOutlined className={classes.icons} /></div>
            <div className={classes.overlay} style={{ display: state.isLoading ? 'flex' : 'none' }}>
                <CircularProgress size={20} className={classes.progress} />
            </div>
        </div>
    );
}

export default Tags;


const iconPicker = (type, style) => {
    switch (type) {
        case "IMAGE":
            return (<ImageOutlined className={style} />);
        default:
            return (<AttachFileRounded className={style} />);
    }
}