const Styles = theme => ({
    itemNested: {
        margin: '0px',
        paddingLeft: theme.spacing(4),
        borderRadius: '10px',
        '& .MuiListItemIcon-root': {
            color: theme?.palette?.scheme?.secondary?.main,
        },
        '& .MuiListItemText-root': {
            color: theme?.palette?.scheme?.secondary?.main,
        },
        '& .MuiSvgIcon-root':{
            fontSize: '0.6rem',
        }
    },

    itemNestedActive: {
        backgroundColor: 'transparent',
        '& .MuiListItemIcon-root': {
            color: theme?.palette?.scheme?.accent?.main,
        },
        '& .MuiListItemText-root': {
            color: theme?.palette?.scheme?.accent?.main,
        },
        '& .MuiSvgIcon-root':{
            fontSize: '1.2rem',
        }
    }
})

export default Styles;