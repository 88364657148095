import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, Button } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CategoryRow, ContentCard } from '../../../../components';
import { usePopupOverlay } from '../../../../components/popup-overlay';
import ConfirmationDeleteRender from '../../Popups/ConfirmationDeleteRender';
import * as Utils from '../../../../service/utils/functions';
import { ServiceContext } from '../../../../context/services/service-context';
import * as S3Service from '../../../../service/s3'
import Styles from './style.js';
import EnterpriseFormRender from '../../Popups/EnterpriseFormRender';



const useStyles = makeStyles(Styles);

const EnterprisesList = ({ history }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { triggerPopupOverlay } = usePopupOverlay();
    const { Enterprises } = useContext(ServiceContext);
    const [enterprisesList, setEnterprisesList] = useState();
    const [selectedEnterprise, setSelectedEnterprise] = useState(null)
    const [openForm, setOpenForm] = useState(false)



    useEffect(() => {
        getEnterprisesList();
    }, []);



    const getEnterprisesList = async () => {
        try {
            let result = await Enterprises.listEnterprises();
            let enterprises = result?.data?.listDemosForEnterpises;
            await Promise.all(
                await enterprises.map(async(enterprises, index) => {
                    await Promise.all(
                        await enterprises.demos.map(async (demo, indexDemo) => {
                            const url = await getImageUrl(demo?.image?.url);
                            enterprises.demos[indexDemo].imageUrl = url;
                        })
                    )
                })
            )
            setEnterprisesList(enterprises);
        } catch (error) {
            console.log(error)
        }
    }

    
    const getImageUrl = async (key) => {
        const url = await S3Service.getFile(key);
        return url;
    }

    /**
     * Handler to edit the information of the category, by id.
     * 
     * @param {object} event 
     */
    const onEditCategoryHandler = (event) => {
        //triggerPopupOverlay(<UpdateCategoryPopup {...event} />, t('enterprises-popup-update-title'), getEnterprisesList);
    }

    /**
     * Handler to delete the category and all their galleries.
     * 
     * @param {object} event 
     */
    const onDeleteCategoryHandler = async (event) => {
        const params = { id: event.data.id }
        triggerPopupOverlay(<ConfirmationDeleteRender description={t('enterprises-popup-delete-description')} />, t('enterprises-popup-delete-title'), async () => {
            await Enterprises.deleteEnterprise(params);
            await getEnterprisesList();
        });
    }

    /**
     * Handler to go to the edit page for the specific Museum, by id.
     * 
     * @param {object} event 
     */
    const onEditEnterpriseHandler = async (event) => {
        setSelectedEnterprise(event.data)
        setOpenForm(true)
    }

    /**
     * Handler to delete a Museum
     * 
     * @param {object} event 
     */
    const onRemoveDemoAssociationHandler = async (event) => {
        try {
            const associationId = event.data.associationId
            const params = {
                associationId: associationId
            }
            triggerPopupOverlay(<ConfirmationDeleteRender description={t('enterprises-popup-remove-association-description')} />, t('enterprises-popup-remove-association-title'), async () => {
                await Enterprises.removeDemoToEnterpriseAssociation(params);
                await getEnterprisesList();
            });
        } catch (error) {
            
        }
    }

    const onSubmitEnterpriseHander = async (data) => {
        try {
            const id = selectedEnterprise?.id
            if (selectedEnterprise) {
                updateEnterprise(data, id)
            } else {
                createEnterprise(data)
            }
            setSelectedEnterprise(null)
            setOpenForm(false)
           
        } catch (error) {

        }
    }

    const createEnterprise = async (data) => {
        const postData = { title: data }
        await Enterprises.createEnterprise(postData)
        getEnterprisesList()
    }

    const updateEnterprise = async (data, id) => {
        const postData = { title: data, id: id }
        await Enterprises.updateEnterprise(postData)
        getEnterprisesList()
    }




    return (
        <Grid>
            <Grid item xs style={{display: 'flex', justifyContent: 'center', marginBottom: '30px'}}>
                <Button variant="contained" color="primary" onClick={() => setOpenForm(true)}>
                    {t('enterprises-list-demos-add-enterprise')}
                </Button>
            </Grid>
           
            {
                enterprisesList ?
                    enterprisesList.length > 0 ?
                        enterprisesList.map((prop, index1) => {
                            return (
                                <CategoryRow
                                    numberItems={prop?.demos?.length}
                                    key={`category-row-${index1}`}
                                    data={prop}
                                    title={Utils.getTextLanguage(prop?.title, i18n.language)}
                                    actions={{
                                        onEdit: onEditEnterpriseHandler,
                                        onDelete: onDeleteCategoryHandler
                                    }}>
                                        
                                        {

                                            prop?.demos?.length > 0 ?
                                                prop?.demos?.map((item, index2) => {
                                                    return <ContentCard
                                                        key={`item-card-${index2}`}
                                                        title={Utils.getTextLanguage(item?.title, i18n.language)}
                                                        subtitle={Utils.getTextLanguage(item?.subtitle, i18n.language)}
                                                        description={Utils.getTextLanguage(item?.description, i18n.language)}
                                                        thumbnail={item?.imageUrl}
                                                        data={item}
                                                        actions={{
                                                            onDelete: onRemoveDemoAssociationHandler
                                                        }}
                                                    />
                                                })
                                                : null
                                        }
                                    <div className={classes.placeholderCard} onClick={() => {
                                        history.push(`/enterprises/${prop.id}/associate`);
                                    }}>
                                        <PlaylistAddIcon />
                                    </div>
                                </CategoryRow>
                            );
                        })
                        :
                        <div className={classes.progressContainer}>
                            <span className={classes.label} style={{ justifyContent: 'center' }}>{t('enterprises-list-demos-empty-message')}</span>
                        </div>
                    :
                    <div style={{ flex: '1', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={50} />
                    </div>
            }
            {openForm && (
                <EnterpriseFormRender
                    open={openForm}
                    data={selectedEnterprise}
                    actions={{
                        onCancel: () => setOpenForm(false),
                        onSubmit: onSubmitEnterpriseHander
                    }}
                />
            )}
        </Grid >
    )
}

export default withRouter(EnterprisesList);
