const Styles = theme => ({
    progressContainer: {
        flex: '1', 
        height: '300px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    label: {
        width: '100%',
        height: 'auto',
        fontSize: '27px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    placeholderCard: {
        cursor: 'pointer',
        width: '100px',
        height: '250px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        margin: '0px 30px 30px 0px',
        backgroundColor: 'rgba(219, 67, 50, 1) !important',
        boxShadow: '1px 1px 10px #EEEEEE',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: 'rgba(219, 67, 50, 0.8) !important',
        },
        [theme?.breakpoints?.between('0', '600')]: {
            width: '100%',
            height: '250px',
        },
        '& svg': {
            width: '1.5em',
            height: '1.5em',
            color:'white'
        }
    }
})

export default Styles;