import { LibraryBooksOutlined, PostAdd, FolderOutlined} from '@material-ui/icons';

import DemosList from './Views/DemosList';
import DemosCreate from './Views/DemosCreate';

export const Structer = {
    title: 'demos-page-title',
    sections: [
        {
            id: 'list',
            index: 0,
            label: 'demos-nav-list',
            icon: <LibraryBooksOutlined />,
            path: '/demos/list',
            component: <DemosList />,
        },
        {
            id: 'create',
            index: 1,
            label: 'demos-nav-create',
            icon: <PostAdd />,
            path: '/demos/create',
            component: <DemosCreate />,
        }
    ],
}