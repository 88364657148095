import { createMuiTheme } from '@material-ui/core';
import overrides from './overrides';
import typography from './typography';
import palette from './palette';
import breakpoints from './breakpoints';

const Theme = (theme = null) => {

    let sample = palette;

    if (theme) {
        sample = theme
    }

    let muiTheme = createMuiTheme({
        overrides: overrides(sample),
        typography: typography(sample),
        palette: palette(sample),
        breakpoints
    });
    return muiTheme
}

export default Theme;
