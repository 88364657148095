import { API, graphqlOperation } from "aws-amplify";
import { getDemosForEnterprise, listDemos, getDemo } from '../../../graphql/customQueries';
import { createDemo, updateDemo, deleteDemo } from '../../../graphql/mutations';


export const getDemosByEnterprise = async (data) => {
    return await API.graphql(graphqlOperation(getDemosForEnterprise, { input: data }));
}

export const listAllDemos = async () => {
    return await API.graphql(graphqlOperation(listDemos));
}

export const createNewDemo = async (data) => {
    return await API.graphql(graphqlOperation(createDemo, data));
}

export const getDemoById = async (data) => {
    return await API.graphql(graphqlOperation(getDemo, { input: data }));
}

export const UpdateDemo = async (data) => {
    return await API.graphql(graphqlOperation(updateDemo, data));
}

export const DeleteDemo = async (data) => {
    return await API.graphql(graphqlOperation(deleteDemo, data));
}

