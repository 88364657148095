import cyc_logo_full from './logo cycloid.svg';
import cyc_logo from './Cycloid_RGB_Icon.svg';
import cyc_logo_parcial from './Cycloid_RGB_Positive.svg';
import cy_bg_aws_media_demos from './cy-bg-aws-media-demos.jpg';
import empty_image from './empty_image.jpg';
import error_image from './error_image.jpg';
import doc_thumbnail from './doc_type_thumbnail.jpg';
import image_thumbnail from './image_type_thumbnail.jpg';
import cy_bullets from './cy bullets decor.svg'

const Images = {
    cyc_logo_full: cyc_logo_full,
    cyc_logo: cyc_logo,
    cyc_logo_parcial: cyc_logo_parcial,
    cy_bg_aws_media_demos: cy_bg_aws_media_demos,
    empty: empty_image,
    error_image: error_image,
    doc_thumbnail: doc_thumbnail,
    image_thumbnail: image_thumbnail,
    cy_bullets: cy_bullets
}

export default Images;