import { PersonRounded, VpnKeyRounded } from '@material-ui/icons';

import Information from './Views/Information';
import ChangePassword from './Views/ChangePassword';

export const Structer = {
    title: 'account-page-title',
    sections: [
        {
            id: 'info',
            index: 0,
            label: 'account-nav-information',
            icon: <PersonRounded />,
            path: '/account/info',
            component: <Information />
        },
        {
            id: 'change-password',
            index: 1,
            label: 'account-nav-changepassword',
            icon: <VpnKeyRounded />,
            path: '/account/change-password',
            component: <ChangePassword />
        }
    ],
}