import React  from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, IconButton } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';

import { SessionPopper, LanguageSelector } from '../../components';
import { useSideMenu } from '../../components/sidemenu';

import Assets from '../../assets';


import Styles from './style';

const useStyles = makeStyles(Styles);

const ToolbarMenu = () => {

    const classes = useStyles();
    const [menu, setMenu] = useSideMenu();

    return (
        <Toolbar classes={{
            root: classes.root
        }}>
            <div className={classes.logo}>
                <img src={Assets.Images.cyc_logo_full} alt="logo" />
            </div>
            <div className={classes.rightActions}>
                <LanguageSelector type="app"/>
                <SessionPopper />
            </div>
        </Toolbar>
    );
}

export default ToolbarMenu;