import React, { useContext, useState, useEffect } from 'react';

import { Paper, Grid, TextField, Button, CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import { TextFields, Subject, ArrowBackIosOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import  { useNavBar } from '../../index';
import { LanguageSelector, FileDropzone } from '../../../../components';
import { usePopupOverlay } from '../../../../components/popup-overlay';
import { useNotifications } from '../../../../components/notification-snackbar';
import UploadFilesPopup from '../../../Global/Popups/UploadFiles';
import * as Utils from '../../../../service/utils/functions';
import * as S3Service from '../../../../service/s3'
import { ServiceContext } from '../../../../context/services/service-context';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ALL_STATUS = [
    { id: 'ACTIVEONLINE', label: 'Activo online' },
    { id: 'ACTIVEOFFLINE', label: 'Activo offline' },
    { id: 'INACTIVE', label: 'Inactivo' }
]



const DemosUpdate = () => {
    
    const classes = useStyles();
    const history = useHistory();
    const { demosId } = useParams();
    const { Demos } = useContext(ServiceContext);
    const { t, i18n } = useTranslation();
    const [textLang, setTextLang] = useState('pt');
    const [previewImage, setPreviewImage] = useState(null)
    const [demo, setDemo] = useState(null)
    const [isChanged, setIsChanged] = useState(false);



    const updateDemos = useFormik({
        initialValues: {
            title: '{}',
            description: '{}',
            active: false,
            online: false,
            demoUrl: ''
        },
        onSubmit: async (values) => {
            try {
                const postData = {
                    input: {
                        id: demo?.id,
                        active: values.active,
                        demoUrl: {
                            id: demo?.image.id,
                            url: values.demoUrl
                        },
                        description: values.description,
                        image: {
                            id: demo?.image.id,
                            url: values.image
                        },
                        online: values.online,
                        title: values.title
                    }
                }
                await Demos.updateDemo(postData);
                history.push("/demos/list");
            } catch (error) {
            }
        }
    });

    


    const onInputTextChangeHandler = (event) => {
        let field = event.target.id;
        let text = event.target.value;
        let current = updateDemos.values[field];
        let obj = {};

        if (typeof current === 'string') {
            try {
                current = JSON.parse(current);
                if (text === "") {
                    delete current[textLang];
                } else { 
                    current[textLang] = text
                }
                updateDemos.setFieldValue(field, JSON.stringify(current));
            } catch (error) {
                obj[textLang] = text
                updateDemos.setFieldValue(field, JSON.stringify(obj));
            }
        } else if (typeof current === 'object') {
            current = JSON.parse(current);
            current[textLang] = text
            updateDemos.setFieldValue(field, JSON.stringify(current));
        }
        setIsChanged(true)
    }


    const handleChangeUploadFoto = async (event) => {
        try {
            setIsChanged(true)
            const file = event.target.files[0]
            const path = `${new Date().getTime()}_${file?.name}`
            const bucketKey = await S3Service.uploadFile(path, file, null)
            const key = bucketKey?.key
            const url = await S3Service.getFile(key)
            setPreviewImage(url)
            updateDemos.setFieldValue('image', key)
        } catch (error) {
          console.log(error)   
        }
    }

    const onLanguageCallback = (event) => {
        setTextLang(event);
    }



    useEffect(() => {
        const getDemo = async () => {
            try {
                const resp = await Demos.getDemosById({ id: demosId })
                const _demo = resp.getDemo
                const url = await S3Service.getFile(_demo?.image?.url)
                updateDemos.setFieldValue('title', _demo?.title)
                updateDemos.setFieldValue('description', _demo?.description)
                updateDemos.setFieldValue('demoUrl', _demo?.demoUrl?.url)
                updateDemos.setFieldValue('active', _demo?.active)
                updateDemos.setFieldValue('online', _demo?.online)
                updateDemos.setFieldValue('image', _demo?.image?.url)
                setDemo(_demo)
                setPreviewImage(url)
            } catch (error) {
                
            }
        }
        getDemo()
    }, [demosId])


    return (
      
           <div className={classes.root}>
            <div className={classes.title}>{t('demos-page-title')}</div>
            <div className={classes.label}>{Utils.getTextLanguage(demo?.title, i18n.language)}</div>
            <br />
            <div className={classes.header} onClick={() => { history.push('/demos/list') }}>
                <ArrowBackIosOutlined className={classes.icons} />
                <p>{t('action-btn-label-back')}</p>
            </div>
            <br />
            <div className={classes.grid}>
                {
                    demo ?
                        <Grid container spacing={4}>
                            <Grid item sm>
                                <br /><br />
                                <Paper elevation={0} className={classes.paper}>
                                    <div className={classes.inputLanguage}>
                                        <LanguageSelector type="input" actions={{ onLanguageChangeCallback: onLanguageCallback }} />
                                    </div>
                                    <form onSubmit={updateDemos.handleSubmit} className={classes.form} >
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="title"
                                                    type='text'
                                                    label={t('demos-create-input-title')}
                                                    placeholder={t('demos-create-input-title')}
                                                    variant="outlined"
                                                    InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                                                    onChange={onInputTextChangeHandler}
                                                    defaultValue={Utils.getTextLanguage(updateDemos.values.title, textLang)}
                                                    value={Utils.getTextLanguage(updateDemos.values.title, textLang)}
                                                    required
                                                />
                                                <TextField
                                                    id="description"
                                                    type='text'
                                                    label={t('demos-create-input-description')}
                                                    placeholder={t('demos-create-input-description')}
                                                    variant="outlined"
                                                    InputProps={{ startAdornment: <Subject className={classes.icons} /> }}
                                                    onChange={onInputTextChangeHandler}
                                                    defaultValue={Utils.getTextLanguage(updateDemos.values.description, textLang)}
                                                    value={Utils.getTextLanguage(updateDemos.values.description, textLang)}
                                                    rows={8}
                                                    multiline
                                                    classes={{
                                                        root: classes.textArea,
                                                    }}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                                <div className={classes.boxImage}>
                                                    <img src={previewImage} alt='' />
                                                </div>
                                                {previewImage === null ? (
                                                    <>
                                                        <label className={classes.labelUploadImage} htmlFor="upload-button">{t('demos-load-image-button')}</label>
                                                        <input
                                                            accept="image/*"
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={handleChangeUploadFoto}
                                                        />
                                                    </>
                                                ) : (
                                                    <IconButton
                                                        aria-label="delete"
                                                        className={classes.margin}
                                                        onClick={() => {
                                                            setIsChanged(true)
                                                            setPreviewImage(null)
                                                            updateDemos.setFieldValue('image', null)
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ alignItems: 'flex-end', justifyContent: 'space-around' }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    id="demoUrl"
                                                    type='text'
                                                    label={t('demos-create-input-url')}
                                                    placeholder={t('demos-create-input-url')}
                                                    variant="outlined"
                                                    InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                                                    onChange={(event) => {
                                                        setIsChanged(true)
                                                        updateDemos.handleChange('demoUrl', event.target.value)
                                                    }}
                                                    defaultValue={updateDemos.values.demoUrl}
                                                    value={updateDemos.values.demoUrl}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <div className={classes.column}>
                                            <div className={classes.switchBox}>
                                                <FormControlLabel control={
                                                    <Switch
                                                        color="primary"
                                                        checked={updateDemos.values.active}
                                                        onChange={(event) => {
                                                            setIsChanged(true)
                                                            updateDemos.setFieldValue('active', event.target.checked)
                                                        }}
                                                    />
                                                }
                                                    label={t('demos-create-input-active')}
                                                    style={{ marginRight: '40px' }}
                                                />

                                                <FormControlLabel control={
                                                    <Switch
                                                        color="primary"
                                                        checked={updateDemos.values.online}
                                                        onChange={(event) => {
                                                            setIsChanged(true)
                                                            updateDemos.setFieldValue('online', event.target.checked)
                                                        }}
                                                    />
                                                }
                                                    label={t('demos-create-input-online')}
                                                    style={{ margin: '20px 0' }}
                                                />
                                            </div>
                                        </div>
                                        <Button type='submit' variant="contained" color='primary' disableElevation disabled={!isChanged} classes={{ root: classes.button }}>{
                                            updateDemos.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('demos-update-btn')
                                        }</Button>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                        :
                        <div style={{ flex: '1', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={50} className={classes.progress} />
                        </div>
                }
            </div>
        </div>
    );
}

export default DemosUpdate;