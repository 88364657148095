const Styles = theme => ({
    main: {
        maxWidth: '100vw',
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    header: {
        maxWidth: '100vw',
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    contentHeader: {
        height: 'auto',
        padding: '20px 0px',
        width: '100%',
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'center',
        maxWidth: '650px',
        marginBottom: '100px',
        display: 'flex',
        // borderBottom: '1px solid grey',
        alignItems: 'center',
        '& h1': {
            color: 'white',
            fontWeight: 700
        },
        [theme?.breakpoints?.down('sm')]: {
            // marginBottom: '0px',
            // flexDirection: 'column',
            borderBottom: '0px',
        padding: '0px',
        }
    },
    contentHeaderTitle: {
        ...theme?.typography?.title_h1,
        fontSize: '35px',
        [theme?.breakpoints?.down('sm')]: {
            fontSize: '30px',
            margin: '20px',
        }
    },
    logo: {
        width: 'auto',
        height: '55px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0px 50px 0px',
        '& img': {
            height: '55px'
        }
    },
    logo: {
        width: 'auto',
        height: '55px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
    },
    form: {
        width: '100%'
    },
    paper: {
        maxWidth: '375px',
    },
    footer: {
        width: '100%',
        padding: '60px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& div img': {
            width: '80px',
            padding: '0px 7px'
        }
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '7px',
        ...theme?.typography?.title_h2,
    },
    subtitle: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme?.typography?.subtitle,
    },
    subtext: {
        justifyContent: 'flex-start',
        ...theme?.typography?.label,
    },
    anchor: {
        cursor: 'pointer',
        justifyContent: 'flex-start',
        ...theme?.typography?.anchor,
    },
    icons: {
        fontSize: '35px',
        marginRight: '10px',
        color: theme?.palette?.scheme?.accent?.main
    },
    progress: {
        color: theme?.palette?.scheme?.accent?.main
    },
})

export default Styles;