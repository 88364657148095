








export const setClientApp = async (client) => new Promise((resolve, reject) => {
    sessionStorage.setItem('client', client)
    resolve(client)
})


export const getClientApp = async () => new Promise((resolve, reject) => {
    const item = sessionStorage.getItem('client')
    resolve(item)
})

