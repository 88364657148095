const _default = {
    scheme: {
        primary: {
            main: '#d0212a'
        },
        secondary: {
            main: '#969696'
        },
        accent: {
            main: '#969696', 
            hover: '#969696',
            disabled: 'rgba(0, 0, 0, 0.45)',
        },
    },
    font: {
        primary: 'rgba(71, 71, 71, 1)',
        secundary: 'rgba(194, 194, 194, 1)',
        link: '#d0212a'
    }
}

const palette = (theme) => {

    let palette = _default;

    if(theme){
        palette = theme
    }

    return palette
}

export default palette;