const Styles = theme => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme?.breakpoints?.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        [theme?.breakpoints?.between('0', '600')]: {
            minWidth: 'auto',
        },
        padding: '0px 7px',
    },
    paper:{
        display: 'flex',
        flexDirection: 'column',
        padding: '15px'
    },
    userPhoto: {
        width: '200px',
        height: '200px',
        fontSize: '80px',
        margin: '0px 20px 20px 20px'
    },
    userName: {
        ...theme?.typography?.title_h2,
    },
    userRole: {
        padding: '4px 0px',
        ...theme?.typography?.subtitle,
    },
    button: {
        margin: '25px 0px 0px auto !important',
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: theme?.palette?.scheme?.accent?.main
    },
    progress: {
        color: theme?.palette?.scheme?.accent?.main
    },


})

export default Styles;