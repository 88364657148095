const MuiTextField = (theme) => {
    return {
        root: {
            width: '100%',
            margin: '10px 0px',
            '& .MuiInputBase-root': {
                backgroundColor: '#FFFFFF'
            },
            "& .MuiOutlinedInput-root": {
                "& .Mui-disabled ": {
                    color: theme?.scheme?.accent?.disabled
                },
                '& fieldset': {
                    borderColor: '#c8c8c8'
                },
                '&:hover fieldset': {
                    borderColor: theme?.scheme?.accent?.hover
                },
                '&.Mui-focused fieldset': {
                    borderColor: theme?.scheme?.accent?.hover
                },
                '&.Mui-disabled fieldset': {
                    borderColor: theme?.scheme?.secondary?.main
                },
                "&.Mui-disabled svg": {
                    color: theme?.scheme?.accent?.disabled
                },
                "& svg": {
                    color: theme?.scheme?.secondary.main
                },
                '& .MuiInputAdornment-root': {
                    '& svg': {
                       color: '#d0212a'
                   } 
                }
            },
            "& .MuiInputLabel-root":{
                '&.Mui-disabled': {
                    color: theme?.scheme?.secondary?.main
                }
            },
            "&:hover .MuiInputLabel-root":{
                color: theme?.scheme?.accent?.main
            }
        },
    }
};

export default MuiTextField;