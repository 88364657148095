import React, { useContext, useState } from 'react';

import { Paper, Grid, TextField, Button, CircularProgress, FormControlLabel, Switch } from '@material-ui/core';
import { TextFields, Subject } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import  { useNavBar } from '../../index';
import { LanguageSelector } from '../../../../components';
import { usePopupOverlay } from '../../../../components/popup-overlay';
import * as Utils from '../../../../service/utils/functions';
import * as S3Service from '../../../../service/s3'
import { ServiceContext } from '../../../../context/services/service-context';

import { useNotifications } from '../../../../components/notification-snackbar';


import Styles from './style.js';

const useStyles = makeStyles(Styles);




const DemosCreate = () => {
    
    const classes = useStyles();
    const { Demos } = useContext(ServiceContext);
    const { triggerNotification } = useNotifications();
    const { t, i18n } = useTranslation();
    const { goToTab } = useNavBar();
    const [textLang, setTextLang] = useState('pt');
    const [previewImage, setPreviewImage] = useState(null)


    const createDemos = useFormik({
        initialValues: {
            title: '{}',
            description: '{}',
            active: false,
            online: false,
            demoUrl: ''
        },
        onSubmit: async (values) => {
            if (!Utils.isValidByLanguage(values, ['title', 'subtitle', 'description'], i18n.languages)) {
                triggerNotification("error", "notifications-missingLanguage-Error-message", "notifications-missingLanguage-Error-title");
                return
            }
            try {
                const postData = {
                    input: {
                        active: values.active,
                        demoUrl: values.demoUrl,
                        description: values.description,
                        image: values.image,
                        online: values.online,
                        title: values.title
                    }
                }
                await Demos.createDemos(postData);
                goToTab(0)
            } catch (error) {
            }
        }
    });

    


    const onInputTextChangeHandler = (event) => {
        let field = event.target.id;
        let text = event.target.value;
        let current = createDemos.values[field];
        let obj = {};

        if (typeof current === 'string') {
            try {
                current = JSON.parse(current);
                if (text === "") {
                    delete current[textLang];
                } else { 
                    current[textLang] = text
                }
                createDemos.setFieldValue(field, JSON.stringify(current));
            } catch (error) {
                obj[textLang] = text
                createDemos.setFieldValue(field, JSON.stringify(obj));
            }
        } else if (typeof current === 'object') {
            current = JSON.parse(current);
            current[textLang] = text
            createDemos.setFieldValue(field, JSON.stringify(current));
        }
    }


    const handleChangeUploadFoto = async (event) => {
        try {
            const file = event.target.files[0]
            const path = `${new Date().getTime()}_${file?.name}`
            const bucketKey = await S3Service.uploadFile(path, file, null)
            const key = bucketKey?.key
            const url = await S3Service.getFile(key)
            setPreviewImage(url)
            createDemos.setFieldValue('image', key)
        } catch (error) {
          console.log(error)   
        }
    }

    const onLanguageCallback = (event) => {
        setTextLang(event);
    }



    return (
        <Grid container spacing={4}>
            <Grid item sm>
                <br /><br />
                <Paper elevation={0} className={classes.paper}>
                    <div className={classes.inputLanguage}>
                        <LanguageSelector type="input" actions={{ onLanguageChangeCallback: onLanguageCallback }} />
                    </div>
                    <form onSubmit={createDemos.handleSubmit} className={classes.form} >
                        <Grid container>
                            <Grid item xs={8}>
                                <TextField
                                    id="title"
                                    type='text'
                                    label={t('demos-create-input-title')}
                                    placeholder={t('demos-create-input-title')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                                    onChange={onInputTextChangeHandler}
                                    defaultValue={Utils.getTextLanguage(createDemos.values.title, textLang)}
                                    value={Utils.getTextLanguage(createDemos.values.title, textLang)}
                                    required
                                />
                                <TextField
                                    id="description"
                                    type='text'
                                    label={t('demos-create-input-description')}
                                    placeholder={t('demos-create-input-description')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <Subject className={classes.icons} /> }}
                                    onChange={onInputTextChangeHandler}
                                    defaultValue={Utils.getTextLanguage(createDemos.values.description, textLang)}
                                    value={Utils.getTextLanguage(createDemos.values.description, textLang)}
                                    rows={8}
                                    multiline
                                    classes={{
                                        root: classes.textArea,
                                    }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div className={classes.boxImage}>
                                    <img src={previewImage} alt='' /> 
                                </div>
                                {previewImage === null ? (
                                    <>
                                        <label className={classes.labelUploadImage} htmlFor="upload-button">{t('demos-load-image-button')}</label>
                                        <input
                                            accept="image/*"
                                            type="file"
                                            id="upload-button"
                                            style={{ display: "none" }}
                                            onChange={handleChangeUploadFoto}
                                        />
                                    </>
                                ) : (
                                        <IconButton
                                            aria-label="delete"
                                            className={classes.margin}
                                            onClick={() => {
                                                setPreviewImage(null)
                                                createDemos.setFieldValue('demoUrl', null)
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ alignItems: 'flex-end', justifyContent: 'space-around' }}>
                            <Grid item xs={12}>
                                <TextField
                                    id="demoUrl"
                                    type='text'
                                    label={t('demos-create-input-url')}
                                    placeholder={t('demos-create-input-url')}
                                    variant="outlined"
                                    InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                                    onChange={createDemos.handleChange}
                                    defaultValue={createDemos.values.demoUrl}
                                    value={createDemos.values.demoUrl}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.column}>
                            <div className={classes.switchBox}>
                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        checked={createDemos.values.active}
                                        onChange={(event) => createDemos.setFieldValue('active', event.target.checked)}
                                    />
                                }
                                    label={t('demos-create-input-active')}
                                    style={{ marginRight: '40px' }}
                                />

                                <FormControlLabel control={
                                    <Switch
                                        color="primary"
                                        checked={createDemos.values.online}
                                        onChange={(event) => createDemos.setFieldValue('online', event.target.checked )}
                                    />
                                }
                                    label={t('demos-create-input-online')}
                                    style={{ margin: '20px 0' }}
                                />
                            </div>
                        </div>
                         <Button type='submit' variant="contained" color='primary' disableElevation disabled={createDemos.isSubmitting} classes={{ root: classes.button }}>{
                            createDemos.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('demos-create-btn')
                        }</Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default DemosCreate;