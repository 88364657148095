import HandIcon from '../../../assets/images/Icon_handpoint_white.png';


const classes = theme => ({
    root: {
        width: '300px',
        height: '320px',
        borderRadius: '0px 12px 12px 12px',
        margin: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        backgroundColor: '#d0212a',
        '&:hover': {
            backgroundColor: '#94070a'
        },
        backgroundImage: `url(${HandIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    header: {
        height: '103px',
        display: 'flex',
        flexDirection: 'column',
        '& .header-inner': {
            padding: '16px',
            '& h2': {
                color:'#ffffff',
                fontFamily: 'Open Sans',
                fontSize: '24px',
                fontWeight: 'bold',
                lineHeight: 1,
                margin: '0 0 5px'
            },
            '& h3': {
                color:'#ffffff',
                fontFamily: 'Open Sans',
                fontSize: '18px',
                 lineHeight: 1,
                margin: 0
            }
        }
    }
})
export default classes;