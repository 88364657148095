import { API, graphqlOperation } from "aws-amplify";
import { listDemosForEnterpises, getAvailableDemosForEnterprise, updateEnterprise} from '../../../graphql/customQueries';
import { createEnterprise, deleteEnterprise, associateDemoToEnterprise, removeDemoToEnterpriseAssociation } from '../../../graphql/mutations';


export const ListDemosByEnterprise = async () => {
    return await API.graphql(graphqlOperation(listDemosForEnterpises));
}


export const GetAvailableDemosForEnterprise = async (data) => {
    return await API.graphql(graphqlOperation(getAvailableDemosForEnterprise, { input: data}));
}

export const CreateNewEnterprise = async (data) => {
    return await API.graphql(graphqlOperation(createEnterprise, { input: data }));
}

export const UpdateEnterprise = async (data) => {
    return await API.graphql(graphqlOperation(updateEnterprise, { input: data }));
}



export const DeleteEnterprise = async (data) => {
    return await API.graphql(graphqlOperation(deleteEnterprise, { input: data }));
}

export const AssociateDemoToEnterprise = async (data) => {
    return await API.graphql(graphqlOperation(associateDemoToEnterprise, { input: data }));
}

export const RemoveDemoToEnterpriseAssociation = async (data) => {
    return await API.graphql(graphqlOperation(removeDemoToEnterpriseAssociation, { input: data }));
}





