import React, { useRef, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { List, MenuItem, ListItemIcon, IconButton, Popper, Paper, Grow, ClickAwayListener, Tooltip } from '@material-ui/core';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AccountIcon from '@material-ui/icons/AccountCircle';
import { AuthContext } from '../../context/auth/auth-context';


import Styles from './style';

const useStyles = makeStyles(Styles);

const SessionPopper = (props) => {

    const { attributes, signout } = useContext(AuthContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const popperRef = useRef();
    const [popper, setPopper] = useState(false);

    const handlePopper = () => {
        setPopper(!popper);
    };



    return (
        <div className={classes.root}>

            <Tooltip title="User">
                <IconButton
                    ref={popperRef}
                    onClick={handlePopper}
                    aria-controls={popper ? 'menu-list-grow' : undefined}
                    aria-haspopup="true">
                    <AccountIcon style={{ color: '#000', width: 24, height: 24 }} />
                </IconButton>
            </Tooltip>

            <Popper
                anchorEl={popperRef.current}
                placement="bottom-end"
                open={popper}
                className={classes.pooper}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}>
                        <Paper elevation={0} className={classes.paper}>
                            <div className={classes.arrowUp} />
                            <ClickAwayListener onClickAway={() => setPopper(false)}>
                                <List id="menu-list-grow" autoFocus={false} autoFocusItem={false} className={classes.menuList}>
                                    <div className={classes.userHeader}>
                                        {t('user-menu-header')}
                                    </div>
                                    <div key='user' className={classes.userInfoBox}>
                                        <span>{attributes.name ? attributes.name : attributes.email.split('@')[0]}</span>
                                        {attributes.email}
                                    </div>
                                    <MenuItem
                                        className={classes.menuItem}
                                        onClick={() => signout()}>
                                        {t('user-menu-logout')}
                                        <ListItemIcon style={{ minWidth: 'auto', marginLeft: 'auto', color: '#000' }}>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                    </MenuItem>

                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

export default SessionPopper;