const MuiTabs = (theme) => {
    return {
        root: {
            color: theme?.scheme?.accent?.main,
        },
        indicator:{
            backgroundColor: theme?.scheme?.accent?.main
        }
    }
};

export default MuiTabs;