
export const getDemosForEnterprise = /* GraphQL */ `
  query GetDemosForEnterprise($input: getDemosForEnterpiseInput) {
    getDemosForEnterprise(input: $input) {
      id
      title
      demos {
        id
        title
        description
        image {
          id
          url
        }
        demoUrl {
          id
          url
        }
        active
        online
        associationId
      }
    }
  }
`;

export const createDemo = /* GraphQL */ `
  mutation CreateDemo($input: createDemoInput) {
    createDemo(input: $input) {
      id
      title
      description
      image {
        id
        url
      }
      demoUrl {
        id
        url
      }
      active
      online
      associationId
    }
  }
`;

export const listDemosForEnterpises = /* GraphQL */ `
  query ListDemosForEnterpises {
    listDemosForEnterpises {
      id
      title
      demos {
        active
        demoUrl {
          id
          url
        }
        description
        title
        online
        image {
          id
          url
        }
        id
        associationId
      }
    }
  }
`;

export const listDemos = /* GraphQL */ `
  query ListDemos {
    listDemos {
      active
      associationId
      demoUrl {
        id
        url
      }
      description
      id
      image {
        id
        url
      }
      online
      title
    }
  }
`;

export const getDemo = /* GraphQL */ `
  query GetDemo($input: getDemoInput) {
    getDemo(input: $input) {
      id
      title
      description
      image {
        id
        url
      }
      demoUrl {
        id
        url
      }
      active
      online
    }
  }
`;

export const getAvailableDemosForEnterprise = /* GraphQL */ `
  query GetAvailableDemosForEnterprise( $input: getAvailableDemosForEnterpriseInput ) {
    getAvailableDemosForEnterprise(input: $input) {
      id
      title
      description
      image {
        id
        url
      }
      demoUrl {
        id
        url
      }
      active
      online
      associationId
    }
  }
`;

export const updateEnterprise = /* GraphQL */ `
  mutation UpdateEnterprise($input: updateEnterpriseInput) {
    updateEnterprise(input: $input) {
      id
      title
    }
  }
`;



