import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import {
    Room, AttachFileRounded, ImageOutlined, MovieOutlined, AudiotrackOutlined, InsertDriveFileOutlined,
    ThreeDRotationOutlined, LinkOutlined, LanguageOutlined, CloseOutlined, PhoneRounded, AlternateEmailRounded,
    LocationOnRounded
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const Tags = (props) => {

    const classes = useStyles();
    const [state, setState] = useState({ isLoading: false })

    const onActionHandler = async () => {
        setState({ ...state, isLoading: true });
        await props.action();
        setState({ ...state, isLoading: false });
    }

    return (
        <div className={classes.root}>
            <div className={classes.icon}>{iconPicker(props.icon, classes.iconTag)}</div>
            <p>{props.label}</p>
            <div className={classes.action} onClick={onActionHandler}><CloseOutlined className={classes.icons} /></div>
            <div className={classes.overlay} style={{ display: state.isLoading ? 'flex' : 'none' }}>
                <CircularProgress size={20} className={classes.progress} />
            </div>
        </div>
    );
}

export default Tags;


const iconPicker = (type, style) => {
    switch (type) {
        case "IMAGE":
            return (<ImageOutlined className={style} />);
        case "VIDEO":
            return (<MovieOutlined className={style} />);
            break;
        case "AUDIO":
            return (<AudiotrackOutlined className={style} />);
            break;
        case "DOCUMENT":
            return (<InsertDriveFileOutlined className={style} />);
            break;
        case "THREEDIMENSIONALOBJECT":
            return (<ThreeDRotationOutlined className={style} />);
            break;
        case "APPLINK":
            return (<LinkOutlined className={style} />);
            break;
        case "URL":
            return (<LanguageOutlined className={style} />);
            break;
        case "MAP":
            return (<Room className={style} />);
            break;
        case "PHONE":
            return (<PhoneRounded className={style} />);
            break;
        case "EMAIL":
            return (<AlternateEmailRounded className={style} />);
            break;
        case "LOCATION":
            return (<LocationOnRounded className={style} />);
            break;
        default:
            return (<AttachFileRounded className={style} />);
            break;
    }
}