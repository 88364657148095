import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { PrivateRoutes } from '../routes';
import SideMenuStructer from './config/sidemenu.config';
import { SideMenu, ToolbarMenu } from '../components';
import { AuthContext } from '../context/auth/auth-context.js';



import Styles from './style';

//TEMP
import { listModules } from '../service/api';


const useStyles = makeStyles(Styles);

const PrivateLayout = () => {

    const classes = useStyles();
    const { config, isBo } = useContext(AuthContext);
    const [sideMenu, setSideMenu] = useState([]);

    useEffect(() => {
        createSideMenu(SideMenuStructer);
    }, []);

    /**
     * Create the SideMenu component based on the modules return from de API request,
     * and filtered using the config file.
     * @param {array} structer 
     */
    const createSideMenu = async (structer) => {
        //const modules = await listModules();
        const modules = {
            data: {
                listBOModules: {
                    items: [
                        { id: "81fa23b5-a653-448f-b939-30ee3d2525d2" },
                        { id: "cc8a9990-c781-4938-b5e3-8b3d1a37a65c", },
                        { id: "cc8a9990-2021-4fa8-b5e3-8b3d1a37a65c" }
                    ]
                }
            }
        }
        
        const filteredModules = SideMenuStructer.filter(item => {
            return modules.data.listBOModules.items.some(module => item.id === module.id)
        });
        setSideMenu(filteredModules);
    }


    return (
        <div className={clsx({ [classes.root]: true, [classes.full]: false })}>
            { isBo &&  <SideMenu structer={sideMenu} context={config} />}
            <div className={classes.contentContainer}>
                <div className={classes.toolbarContainer}>
                    <ToolbarMenu />
                </div>
                <div className={classes.viewContainer}>
                    <PrivateRoutes isBo={isBo} />
                </div>
            </div>
        </div>
    );
}

export default PrivateLayout;