import { ExploreOutlined, AddOutlined, BookmarkBorderOutlined} from '@material-ui/icons';

import EnterpriseList from './Views/EnterprisesList';
//import CreateVisitCategory from './Views/CreateCategory';
//import CreateVisit from './Views/CreateVisit';

export const Structer = {
    title: 'enterprises-page-title',
    sections: [
        {
            id: 'list',
            index: 0,
            label: 'enterprises-nav-list',
            icon: <ExploreOutlined />,
            path: '/enterprises/list',
            component: <EnterpriseList />,
        },
        /*
        {
            id: 'create-enterprise',
            index: 1,
            label: 'enterprises-nav-create-enterprise',
            icon: <BookmarkBorderOutlined />,
            path: '/enterprises/create-category',
            component: <CreateVisitCategory />,
        },
        {
            id: 'associate-demo',
            index: 2,
            label: 'enterprises-nav-associate-demo',
            icon: <AddOutlined />,
            path: '/enterprises/create-visit',
            component: <CreateVisit />,
        }
        */
    ],
}