import React, { useContext } from 'react';
import PrivateRoute from './privateRoute';
import { Route, Switch, Redirect } from 'react-router-dom';

/**
 * PRIVATE ROUTE VIEWS
 */
import UserManagement from '../screens/UserManagement';
import AccountView from '../screens/Account';
import DemosView from '../screens/DemosView';


import EnterprisesView from '../screens/Enterprises';
import AssociateDemo from '../screens/Enterprises/Views/AssociateDemo';


import AllDemos from '../screens/Demos';
import DemoUpdateSubview from '../screens/Demos/Views/DemosUpdate';


/**
 * PUBLIC ROUTE VIEWS
 */
import SignIn from '../screens/Auth/SignIn';
import RecoverPassword from '../screens/Auth/RecoverPassword';
import NewPasswordRequired from '../screens/Auth/NewPasswordRequired';
import RecoverPasswordConfirm from '../screens/Auth/RecoverPasswordConfirm';
import Unauthorized from '../screens/Auth/Unauthorized';

import { AuthContext } from '../context/auth/auth-context';


const checkUnauthorized = () => {
    if(window.location.pathname === "/auth/unauthorized"){
        return "/auth/unauthorized";
    }
    return "/auth/login"
}

export const PublicRoutes = (props) => {


    return (
        <Switch>
            <Route path="/auth/login" component={SignIn} exact />
            <Route path="/auth/unauthorized" component={Unauthorized} exact />
            <Route path="/auth/recover-password" component={RecoverPassword} exact />
            <Route path="/auth/recover-password/confirm" component={RecoverPasswordConfirm} exact />
            <Route path="/auth/new-password-required" component={NewPasswordRequired} exact />
            <Redirect from="/" to={checkUnauthorized()} />
        </Switch>
    )
}

export const PrivateRoutes = (props) => {



    const { isBo, isLoading } = useContext(AuthContext);

    const ROUTES_BO = (
        <Switch>

            <PrivateRoute path="/account/:viewId" redirect="/" component={AccountView} exact />
     
            <PrivateRoute path="/enterprises" redirect="/" component={EnterprisesView} exact />
            <PrivateRoute path="/enterprises/:viewId" redirect="/" component={EnterprisesView} exact />
            <PrivateRoute path="/enterprises/:enterpriseId/associate" redirect="/" component={AssociateDemo} exact />

            <PrivateRoute path="/demos" redirect="/" component={AllDemos} exact />
            <PrivateRoute path="/demos/:viewId" redirect="/" component={AllDemos} exact />
            <PrivateRoute path="/demos/:viewId/:demosId" redirect="/" component={DemoUpdateSubview} exact />

            <PrivateRoute path="/" component={UserManagement} exact />

            <Redirect from="/" to="/" />

        </Switch>
    )

    const ROUTES_GENERIC = (
        <Switch>
            <PrivateRoute path="/" component={DemosView} exact />
            <Redirect from="/" to="/" />
        </Switch>
    )

    if (isLoading) return null

    return isBo ? ROUTES_BO : ROUTES_GENERIC

 
}