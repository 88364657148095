import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '../../../../components/notification-snackbar';


import { CategoryRow, ContentCard } from '../../../../components';
import { usePopupOverlay } from '../../../../components/popup-overlay';
import ConfirmationDeleteRender from '../../Popups/ConfirmationDeleteRender';
import * as Utils from '../../../../service/utils/functions';
import { ServiceContext } from '../../../../context/services/service-context';

import Styles from './style.js';

import * as S3Service from '../../../../service/s3'


const useStyles = makeStyles(Styles);

const DemosList = ({ history }) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { Demos } = useContext(ServiceContext);
    const { triggerPopupOverlay } = usePopupOverlay();
    const { triggerNotification } = useNotifications();
    const [demosList, setDemosList] = useState();

    useEffect(() => {
        getAllDemos()
    }, []);

    const getAllDemos = async () => {
        try {
            let data = []
            const result = await Demos.ListDemos();
            const _demos = result?.data?.listDemos
            await Promise.all(
                await _demos.map(async (demo, index) => {
                    const url = await S3Service.getFile(demo?.image?.url)
                    data[index] = {
                        ...demo,
                        imageUrl: url
                    }
                })
            )
            setDemosList(data)
        } catch (error) {
            
        }
    }

    /**
     * Handler to go to the edit page for the specific Quiz, by id.
     * 
     * @param {object} event 
     */
    const onEditDemoHandler = async (event) => {
        history.push(`/demos/list/${event.data.id}`, event.data);
    }

    /**
     * Handler to delete a Quiz
     * 
     * @param {object} event 
     */
    const onDeleteDemoHandler = async (event) => {
        const params = { input : {id: event.data.id } }
        triggerPopupOverlay(<ConfirmationDeleteRender description={t('demos-popup-delete-description')} />, t('demos-popup-delete-title'), async () => {
            try {
                await Demos.deleteDemo(params);
                await getAllDemos();
            } catch (error) {
                const msg = Utils.getTextLanguage(error.message, i18n.language)
                triggerNotification("error", msg, "notifications-default-Error-title");
            }
        });
    }
    

    const onChangeOnlineHandler = async (data) => {
        try {
            const postData = {
                input: {
                    id: data.data.id,
                    online: data.online,
                    active: data.data.active
                }
            }
            await Demos.updateDemo(postData);
            getAllDemos()

        } catch (error) {}
    }

    return (
        <Grid>
            {
                demosList ?
                    demosList.length > 0 ?
                        <CategoryRow hideHeader={true} expand={true} >
                            {
                                demosList.map((item, index) => {
                                    return <ContentCard
                                        key={index}
                                        title={Utils.getTextLanguage(item?.title, i18n.language)}
                                        description={Utils.getTextLanguage(item?.description, i18n.language)}
                                        thumbnail={item?.imageUrl}
                                        data={item}
                                        actions={{
                                            onEdit: onEditDemoHandler,
                                            onDelete: onDeleteDemoHandler,
                                            onChangeOnline: onChangeOnlineHandler,
                                        }}
                                        online={{ isOnline: item.online } }
                                    />
                                })
                            }
                        </CategoryRow>
                        :
                        <div className={classes.progressContainer}>
                            <span className={classes.label} style={{ justifyContent: 'center' }}>{t('demos-list-empty-message')}</span>
                        </div>
                    :
                    <div className={classes.progressContainer}>
                        <CircularProgress size={50} />
                    </div>
            }
        </Grid >
    );
}

export default withRouter(DemosList);