
const Styles = theme => ({
    card: {
        boxShadow: 'none'
    },
    cardContent: {
        width: '100%'
    },
    dropzone: {
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        display: 'flex',
        outline: 'none',
        padding: '10px',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.5,
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    iconBox: {
        margin: '0 20px 0px',
        '& img': {
            width: '80px',
            objectFit: 'cover'
        }
    },
    infoBox: {
        '& h3': {
            fontWeight: 500,
            lineHeight: 1.167,
            marginBottom: '3px'
        },
        '& p': {
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            margin: 0
        }
    }
})

export default Styles;

