import { PeopleRounded, LibraryBooks, AppsRounded, ApartmentRounded} from '@material-ui/icons';

const SideMenuStructer = [
    {
        id: "81fa23b5-a653-448f-b939-30ee3d2525d2",
        label: "sidemenu-item-users",
        icon: <PeopleRounded />,
        path: "/users",
        modules: []
    },
    {
        id: "cc8a9990-c781-4938-b5e3-8b3d1a37a65c",
        label: "sidemenu-item-demos",
        icon: <AppsRounded />,
        path: "/demos",
        modules: []
    },
    {
        id: "cc8a9990-2021-4fa8-b5e3-8b3d1a37a65c",
        label: "sidemenu-item-enterprises",
        icon: <ApartmentRounded />,
        path: "/enterprises",
        modules: []
    },
    // {
    //     id: "9990cc8a-a65c-4fa8-b5e3-8b3d1a37f60p",
    //     label: "sidemenu-item-media",
    //     icon: <PermMediaRounded />,
    //     path: "/media",
    //     modules: []
    // },
    // {
    //     id: "3ec41933-04c7-48f8-84b3-bdb81ee7517a",
    //     label: "sidemenu-item-storage",
    //     icon: <StorageRounded />,
    //     path: "/storage",
    //     modules: [
    //         {
    //             id: "1",
    //             label: "sidemenu-item-storage-files",
    //             path: "/storage/files",
    //         },
    //         {
    //             id: "1",
    //             label: "sidemenu-item-storage-settings",
    //             path: "/storage/settings",
    //         }
    //     ]
    // }
]

export default SideMenuStructer;