const Styles = theme => ({
    root: {
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        backgroundColor: '#fafafa',
        padding: '0 128px',
         [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
         
    },
    leftActions: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        visibility: 'hidden',
        [theme?.breakpoints?.between('0', '600')]: {
            visibility: 'visible',
        },
    },
    rightActions: {
        width: 'auto',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
      logo: {
        width: '148px',
        height: '38px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
    },
})

export default Styles;