import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import ConfirmationRender from './samples/Confirmation';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const popupOverlayStatus = {
    state: { open: false, title: null, children: null, callback: null },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const usePopupOverlay = () => {

    const [state, set] = useState(popupOverlayStatus.state);
    if (!popupOverlayStatus.setters.includes(set)) {
        popupOverlayStatus.setters.push(set);
    }

    useEffect(() => () => {
        popupOverlayStatus.setters = popupOverlayStatus.setters.filter(setter => setter !== set)
    }, []);

    /**
     * Open popup overlay handler
     */
    function open() {
        popupOverlayStatus.setState({ ...state, open: true });
    }

    /**
     * Close popup overlay handler
     */
    function close() {
        popupOverlayStatus.setState({ open: false, title: null, children: null, callback: null });
    }

    async function callback(args) {
        await state.callback(args);
    }

    /**
     * Create a new popup overlay
     * @param {string} type type of the alert. [info, warning, success, error]
     * @param {string} message message of the alert.
     * @param {string} title (optional) title of the alert message.
     * @param {number} timer (optional) value (miliseconds) for the autohide property of the snackbar.
     */
    function triggerPopupOverlay(children, title = null, callback = null) {
        popupOverlayStatus.setState({ open: true, title: title, children: children, callback: callback });
    }

    return { state, open, close, callback, triggerPopupOverlay };
}

popupOverlayStatus.setState = popupOverlayStatus.setState.bind(popupOverlayStatus);

const PopupOverlay = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = usePopupOverlay();

    return (
        <div className={classes.root} style={{display: state.open ? 'flex' : 'none'}}>
            <div className={classes.container}>
                <div className={classes.paper}>
                    <div className={classes.header}>
                        <div className={classes.title}><p>{state.title}</p></div>
                    </div>
                    {state.children}
                </div>
            </div>
        </div>
    )
}

export default PopupOverlay;

export {
    ConfirmationRender
}