import React, { createContext, useEffect, useState } from 'react';



export const AppContext = createContext();

const AppContextProvider = (props) => {

    const [state] = useState({
        config: {},
        theme: {},
        isLoading: true,
        isBo: false
    });

    useEffect(() => {
    }, []);

   

  

  

    return (
        <AppContext.Provider value={{ ...state }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContextProvider;