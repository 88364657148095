const MuiToolbar = (theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            padding: '10px',
        }
    }
};

export default MuiToolbar;