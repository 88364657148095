import React, { useContext } from 'react';

import { ThemeProvider } from '@material-ui/styles';

import { AuthContext } from '../context/auth/auth-context';

import Notifications from '../components/notification-snackbar';
import PopupOverlay from '../components/popup-overlay';

import PrivateLayout from './private-layout';
import PublicLayout from './public-layout';


import Theme from '../theme/sample';

const Layout = () => {

    const { theme, isAuthenticated, isLoading } = useContext(AuthContext);

    return (
        <ThemeProvider theme={Theme(theme)}>
            <PopupOverlay />
            {!isLoading ? (isAuthenticated ? <PrivateLayout /> : (<PublicLayout />)) : null}
            <Notifications />
        </ThemeProvider>
    );
}

export default Layout;