const Styles = theme => ({
    root: {
        position: 'relative',
        zIndex: '10'
    },
    button: {
        margin: '0px !important',
        color: '#ffffff'
    },
    pooper: {
        width: '260px',
        marginRight: '4px'
    },
    paper:{
        padding: '0px',
        backgroundColor: '#fafafa',
        marginTop: '18px'
    },
    menuList: {
        width: '100%',
        padding: '0px 0 12px',
        borderRadius: '6px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#fafafa'
    },
    menuItem: {
        height: '48px',
        margin: '0px',
        padding: '12px 16px',
        backgroundColor: '#fafafa',
        '& span': {
           fontSize: '16px',
           fontWeight: 'bold',
           lineHeight: 1.38,
           textTransform: 'uppercase',
           margin: '0 8px'
        },
        '&:hover': {
            backgroundColor: '#f0f0f0'
        },
        borderTop: '1px solid #f0f0f0'
    },
    langHeader: {
        height: '57px',
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: 0.83,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fafafa'
    },
       arrowUp: {
        position: 'absolute',
        width: 0,
        height: 0,
        right: 24,
        top: 12,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '10px solid #fafafa;'
    },
})

export default Styles;