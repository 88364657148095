import React, { useEffect, useState } from 'react';

import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { usePopupOverlay, ConfirmationRender } from '../popup-overlay';
import Tags from './Components/Tags';
import UploadFilesPopup from './Upload';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const UploadFiles = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [attachments, setAttachments] = useState([]);
    const { triggerPopupOverlay } = usePopupOverlay();


    useEffect(() => {
        setAttachments(props.attachments);
    }, [props.attachments])

    const onAddAttachmentHandler = async () => {
        await props.actions.onCreate();       
    }

    const onDeleteAttachmentHandler = async (id) => {
        triggerPopupOverlay(<ConfirmationRender description={t('component-uploadfiles-popup-delete-title')} />, t('component-uploadfiles-popup-delete-description'), async () => {
            await props.actions.onDelete(id);
        });
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}><p>{t('component-uploadfiles-container-title-label')}</p></div>
            <div className={classes.container}>
                {
                    attachments ?
                        attachments.length > 0 ?
                            attachments.map((prop, index) => {
                                if (prop)
                                    return (<Tags key={index} label={prop.title} icon={prop.type} action={async () => { await onDeleteAttachmentHandler(prop.id) }} />);
                            })
                            : <div className={classes.message}>{t('component-uploadfile-container-message-label')}</div>
                    : <div className={classes.message}>{t('component-uploadfile-container-message-label')}</div>
                }
            </div>
            <div className={classes.footer}>
                <div className={classes.action} onClick={onAddAttachmentHandler}>
                    <Add className={classes.icons} />
                    <p>{t('component-uploadfile-container-anchor-label')}</p>
                </div>
            </div>
        </div>
    );
}
export default UploadFiles;

export{
    UploadFilesPopup
}