const Styles = theme => ({
    item: {
        margin: '7px 0px',
        borderRadius: '10px',
        '& .MuiListItemIcon-root': {
            color: theme?.palette?.scheme?.secondary?.main,
        },
        '& .MuiListItemText-root': {
            color: theme?.palette?.scheme?.secondary?.main,
        },
        '& .MuiSvgIcon-root':{
            color: theme?.palette?.scheme?.secondary?.main,
        }
    },
    itemActive: {
        backgroundColor: `${theme?.palette?.scheme?.accent?.main} !important`,
        '& .MuiListItemIcon-root': {
            color: 'white',
        },
        '& .MuiListItemText-root': {
            color: 'white',
        },
        '& .MuiSvgIcon-root':{
            color: 'white',
        }
    }
})

export default Styles;