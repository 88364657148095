const Styles = theme => ({
    root: {
        width: '130px',
        height: '130px',
        margin: '10px',
        position: 'relative',
        display: 'flex',
        borderRadius: '10px',
        flexDirection: 'column',
        cursor: 'pointer',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 10px #EEEEEE',
        borderRadius: '7px',
        '&:hover': {
            '& $overlay': {
                display: 'flex',
            }
        },
        [theme.breakpoints.between('0', '600')]: {
            width: '115px',
            height: '115px',
        },
    },
    thumbnail: {
        width: '100%',
        height: '78px',
        diplay: 'flex',
        overflow: 'hidden',
        backgroundColor: '#f2f2f2',
        '& img': {
            width: '100%',
            objectFit: 'contain',
        },
        [theme.breakpoints.between('0', '600')]: {
            height: '69px',
        },
    },
    info: {
        width: '100%',
        height: '52px',
        diplay: 'flex',
        padding: '7px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.between('0', '600')]: {
            height: '46px',
        },
    },
    title: {
        flex: 1,
        '& p': {
            fontSize: '14px',
            fontWeight: 500,
            color: '#474747',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    subtitle: {
        display: 1,
        '& p': {
            fontSize: '11px',
            fontWeight: 300,
            color: '#474747',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    description: {
        flex: 1,
        '& p': {
            fontSize: '11px',
            fontWeight: 300,
            color: '#B3B3B3',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
})

export default Styles;