import React, { useState, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { usePopupOverlay } from '../../popup-overlay';

import { ServiceContext } from '../../../context/services/service-context';

import Styles from './style.js';

import UploadFiles from './Views/UploadFiles';
import LocalFiles from './Views/LocalFiles';

const useStyles = makeStyles(Styles);
const SEARCH_RESULT_LIMIT = 18

const UploadFilesPopup = (props) => {

    const classes = useStyles();
    const Context = useContext(ServiceContext);
    const { t } = useTranslation();
    const { close, callback } = usePopupOverlay();
    const [isUploadView, setIsUploadView] = useState(false);

    const onChangeViewHandler = (value) => {
        if(props.direrctUpload){
            close();
        } else {
            setIsUploadView(value)
        }
    }

    useEffect(() => {
        if (props?.localOnly) {
            setIsUploadView(true)
       }
    }, [props])

    return (
        <>
            {
                isUploadView || props.direrctUpload ?
                    <UploadFiles
                        types={props?.types}
                        context={props?.context}
                        data={props?.data}
                        actions={{
                            onCancel: onChangeViewHandler
                        }} />
                    :
                    <LocalFiles
                        resultLimit={SEARCH_RESULT_LIMIT}
                        types={props?.types}
                        context={props?.context}
                        data={props?.data}
                        actions={{
                            onUplaodView: onChangeViewHandler
                        }}
                    />
            }
        </>
    )

}

export default UploadFilesPopup;