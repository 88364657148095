import { CenterFocusStrong } from "@material-ui/icons";

const Styles = theme => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '15px 0px 0px 0px',
        [theme.breakpoints.between('0', '600')]: {
            justifyContent: 'center',
        }
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        padding: '0px 7px',
    },
    progress: {
        color: '#B0191A'
    },
    button: {
        width: 'auto !important',
        margin: '7px !important',
        [theme.breakpoints.between('0', '600')]: {
            width: '100% !important',
        }
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
    dropdown: {
        minWidth: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        "$ .MuiSelect-select": {
            backgroundColor: 'red'
        }
    },
    dropdownSelect: {
        backgroundColor: 'transparent',
        "&:focus": {
            backgroundColor: 'transparent',
        }
    },
    inputLanguage: {
        width: '130px', 
        height: '50px', 
        padding: '0px 5px', 
        position: 'absolute', 
        top: '-50px', 
        right: '15px', 
        borderRadius: '7px', 
        backgroundColor: '#B0191A'
    }
})

export default Styles;