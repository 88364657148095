const MuiButton = (theme) => {
    return {
        root: {
            width: '100%',
            height: '50px',
            margin: '25px 0px 25px 0px',
            borderRadius: '10px',
            textTransform: 'uppercase',
            color: '#FFFFFF'
        },
        contained: {
            color: '#FFFFFF',
            backgroundColor: "#d0212a",
            '&:hover': {
                backgroundColor: "#94070a",
            }
        },
        containedPrimary: {
            backgroundColor: "#d0212a",
            '&:hover': {
                backgroundColor: "#94070a",
            }
        },
        containedSecondary: {
            backgroundColor: "#1E1E1E",
            '&:hover': {
                backgroundColor: "rgb(30,30,30, 0.95)",
                '& .MuiButton-label': {
                    color: '#fff'
                }
            }
        },
        outlined: {
            backgroundColor: "#ffffff",
            color: theme?.font?.secundary,
            border: '1px solid',
            borderColor: theme?.font?.secundary,
        }
    }
};

export default MuiButton;
