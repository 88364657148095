const Styles = theme => ({
    root: {
        flex: 1, 
        margin: '10px 0px 15px 0px'
    },
    header: {
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& p': {
            margin: '0px',
            padding: '0px',
            fontSize: '16px',
            color: 'rgba(132,132,132, 1)',
        }
    },
    footer: {
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        
        '& p': {
            margin: '0px',
            padding: '0px',
            color: '#B0191A',
        }
    },
    container: {
        flex: 1, 
        display: 'flex', 
        flexWrap: 'wrap', 
        justifyContent: 'center', 
        alignItems: 'center', 
        margin: '10px 0px', 
        padding: '10px', 
        border: '1px solid rgba(0, 0, 0, 0.20)', 
        borderRadius: '10px' 
    },
    action: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        cursor: 'pointer',
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
    message: {
        height: "35px", 
        display: 'flex', 
        margin: '10px', 
        justifyContent: 'center', 
        alignItems: 'center',
        color: '#d3d3d3',
    }
})

export default Styles;