/**
 * Append multiple array objects in to one, the method is capable of
 * verify if the paramter in @param source is an array or not.
 * 
 * @param  {array} source - multiple array objects
 * @returns - Array with all the objects appended. 
 */
export function AppendArrayHandler(...source) {
    let array = []
    source.map((prop) => {
        if (Array.isArray(prop)) {
            Array.prototype.push.apply(array, prop);
        }
    });
    return array;
}

export function getTextLanguage(data, lang) {
    let content = data;
    if (!Boolean(content)) {
        return "";
    }
    if (typeof content === 'string') {
        try {
            content = JSON.parse(content);
            if (Boolean(content[lang])) {
                return content[lang]
            } else {
                return ''
            }
        } catch (error) {
            return content
        }
    } else if (typeof content === 'object') {
        content = JSON.parse(content);
        return content[lang]
    }
}

export const getFormattedDate = (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
}

export const filterObjectArrayByPropertie = (data, properties) => {
    let array = [];
    for (let obj in data) {
        let item = {};
        for (let string in properties) {
            item[properties[string]] =  data[obj][properties[string]];
        }
        array.push(item);
    }
    return array;
}

export const isValidByLanguage = (data, props, languages) => {
    for (let i = 0; i < props.length; i++) {
        const prop = props[i]
        if (!data[prop] || data[prop]==="{}" || data[prop]==="") return true
        const value = JSON.parse(data[prop])
        for (let j = 0; j < languages.length; j++) {
            if (!!!value[languages[j]] || value[languages[j]]?.length == 0 ) return false
        }
    }
    return true
}