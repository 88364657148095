import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Styles from './styles';
import {  makeStyles, Card, CardContent } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles(Styles);


const FileDropzone = ({
    hiddenText,
    onAcceptFiles
}) => {

    const classes = useStyles();
    const { t } = useTranslation();


    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            onAcceptFiles(acceptedFiles)
        }
    });


    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.cardContentRoot}>
                        <section className="container">
                            <div {...getRootProps({ className: classes.dropzone})}>
                                <input {...getInputProps()} />
                                <div className={classes.iconBox}>
                                    <CloudUploadIcon />
                                </div>
                                {hiddenText ? null : (
                                    <div className={classes.infoBox}>
                                        <h2>{t('select files')}</h2>
                                        <p>{t('drag files info')}</p>
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

FileDropzone.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    onAcceptFiles: PropTypes.func
}

export default FileDropzone

