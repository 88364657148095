import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './context/auth/auth-context';
import AppContextProvider from './context/app/app-context';
import ServiceContextProvider from './context/services/service-context';

import Layout from './layout';

function App() {
  return (
    <Suspense fallback={<div></div>}>
      <BrowserRouter>
        <AppContextProvider>
          <AuthContextProvider>
            <ServiceContextProvider>
              <Layout />
            </ServiceContextProvider>
          </AuthContextProvider>
        </AppContextProvider>

      </BrowserRouter>
    </Suspense>
  );
}

export default App;
