import { useNotifications } from '../../../../components/notification-snackbar';
import {
    ListDemosByEnterprise,
    GetAvailableDemosForEnterprise,
    CreateNewEnterprise,
    UpdateEnterprise,
    DeleteEnterprise,
    AssociateDemoToEnterprise,
    RemoveDemoToEnterpriseAssociation
} from '../../../../service/api/enterprises';

export const EnterprisesService = () => {
    const { triggerNotification } = useNotifications();

    const listEnterprises = async () => {
        try {
            const result = await ListDemosByEnterprise();
            return result
        } catch (error) {
            console.log(error)
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const getAvailableDemosForEnterprise = async (params) => {
        try {
            const result = await GetAvailableDemosForEnterprise(params);
            return result
        } catch (error) {
            console.log(error)
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const createEnterprise = async (params) => {
        try {
            const result = await CreateNewEnterprise(params);
            if (result.data.hasOwnProperty('createEnterprise'))
                triggerNotification("success", "notifications-enterprises-createEnterprise-success-message", "notifications-enterprises-createEnterprise-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const updateEnterprise = async (params) => {
        try {
            const result = await UpdateEnterprise(params);
            if (result.data.hasOwnProperty('updateEnterprise'))
                triggerNotification("success", "notifications-enterprises-updateEnterprise-success-message", "notifications-enterprises-updateEnterprise-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const deleteEnterprise = async (params) => {
        try {
            const result = await DeleteEnterprise(params);
            if (result.data.hasOwnProperty('deleteEnterprise'))
                triggerNotification("success", "notifications-enterprises-deleteEnterprise-success-message", "notifications-enterprises-deleteEnterprise-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const associateDemoToEnterprise = async (params) => {
        try {
            const result = await AssociateDemoToEnterprise(params);
            if (result.data.hasOwnProperty('associateDemoToEnterprise'))
                triggerNotification("success", "notifications-enterprises-associate-success-message", "notifications-enterprises-associate-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const removeDemoToEnterpriseAssociation = async (params) => {
        try {
            const result = await RemoveDemoToEnterpriseAssociation(params);
            if (result.data.hasOwnProperty('removeDemoToEnterpriseAssociation'))
                triggerNotification("success", "notifications-enterprises-deleteEnterprise-success-message", "notifications-enterprises-deleteEnterprise-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }







    return {
        listEnterprises,
        getAvailableDemosForEnterprise,
        updateEnterprise,
        createEnterprise,
        deleteEnterprise,
        associateDemoToEnterprise,
        removeDemoToEnterpriseAssociation
    }
}