
const classes = theme => ({
    root: {
        width: '300px',
        height: '320px',
        borderRadius: '0px 12px 12px 12px',
        margin: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        backgroundColor: '#f0f0f0'
    },
    header: {
        height: '103px',
        display: 'flex',
        flexDirection: 'column',
        '& .header-inner': {
            padding: '16px',
            '& h2': {
                color:'#000000',
                fontFamily: 'Open Sans',
                fontSize: '24px',
                fontWeight: 'bold',
                margin: 0
            },
            '& h3': {
                color:'#000000',
                fontFamily: 'Open Sans',
                fontSize: '18px',
            }
        }
    },
    imageBox: {
        height: '169px',
        backgroundColor: '#969696',
        '& img': {
            height: '100%',
            width: '100%',
        }
    },
    descriptionBox: {
        height: '100%',
        backgroundColor: '#f0f0f0',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: 1.14,
        color: '#000000',
        padding: '0 16px',
        display: 'box',
        lineClamp: 8,
        boxOrient: 'vertical',
        overflow: 'hidden',
    },
    footer: {
        borderRadius: '0px 0px 12px 12px',
        height: '48px',
        backgroundColor: '#d0212a',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            textTransform: 'uppercase',
            fontFamily: 'Open Sans',
            fonSize: '16px',
            lineHeight: 1.25
        }
    },
    notActive: {
        backgroundColor: '#c8c8c8',
        '& span': {
            color: '#666666'
        }
    }
})
export default classes;