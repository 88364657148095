const MuiTab = (theme) => {
    return {
        root: {
            minWidth: '0px !important',
            padding: '0px',
            margin: '0px 20px 0px 0px'
        },
        wrapper: {
            display: 'flex',
            textTransform: 'none',
            flexDirection: 'row',
            '& .MuiSvgIcon-root': {
                marginRight: '7px'
            }
        },
        labelIcon: {
            minHeight: '0px'
        },
    
        textColorInherit: {
            transition: 'all 0.2s linear',
            color: theme?.scheme?.accent?.disabled,
            '&.Mui-selected': {
                color: theme?.scheme?.accent?.main
            },
        }
    }
};

export default MuiTab;
