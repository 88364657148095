import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core';
import Styles from './style.js';


const useStyles = makeStyles(Styles);

const EnterpriseFormRender = (props) => {

    const { t, i18n } = useTranslation();


    const [value, setValue] = useState(props?.data?.title)

  
    const handleClose = () => {
        props.actions.onCancel()
    }

    const handleSubmit = () => {
        props.actions.onSubmit(value)
    }

   

    return (
        <Dialog open={props?.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                {t('enterprises-form-title')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('enterprises-form-info')}
                </DialogContentText>
                <TextField
                    value={value}
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t('enterprises-form-field-name')}
                    type="text"
                    fullWidth
                    onChange={event => setValue(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="contained">
                    {t('enterprises-form-button-cancel')}
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    {t('enterprises-form-button-create')}
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default EnterpriseFormRender;