import { useNotifications } from '../../../../components/notification-snackbar';
import { getDemosByEnterprise, listAllDemos, createNewDemo, getDemoById, UpdateDemo, DeleteDemo } from '../../../../service/api/demos';

export const DemosService = () => {

    const { triggerNotification } = useNotifications();

    const GetDemosForEnterprise = async (params) => {
        try {
            const result = await getDemosByEnterprise(params);
            return result?.data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const ListDemos = async () => {
        try {
            const result = await listAllDemos();
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const createDemos = async (params) => {
        try {
            const result = await createNewDemo(params);
            if (result.data.hasOwnProperty('createDemos'))
                triggerNotification("success", "notifications-demos-createDemos-success-message", "notifications-demos-createDemos-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const getDemosById = async (params) => {
        try {
            const result = await getDemoById(params);
            return result?.data
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const updateDemo = async (params) => {
        try {
            const result = await UpdateDemo(params);
            if (result.data.hasOwnProperty('updateDemos'))
                triggerNotification("success", "notifications-demos-createDemos-success-message", "notifications-demos-createDemos-success-title")
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const deleteDemo = async (params) => {
        try {
            const result = await DeleteDemo(params);
            if (result.data.hasOwnProperty('deleteDemo'))
                triggerNotification("success", "notifications-demos-deleteDemo-success-message", "notifications-demos-deleteDemo-success-title")
            return result
        } catch (error) {
            if (error.errors[0]?.message) {
                const msg = error.errors[0]?.message
                throw new Error(msg)
            }
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }



    

    return { GetDemosForEnterprise, ListDemos, createDemos, getDemosById, updateDemo, deleteDemo}
}