/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEnterprise = /* GraphQL */ `
  mutation CreateEnterprise($input: createEnterpriseInput) {
    createEnterprise(input: $input) {
      id
      title
      demos {
        id
        title
        description
        image {
          id
          url
        }
        demoUrl {
          id
          url
        }
        active
        online
        associationId
      }
    }
  }
`;
export const deleteEnterprise = /* GraphQL */ `
  mutation DeleteEnterprise($input: deleteEnterpriseInput) {
    deleteEnterprise(input: $input) {
      id
      title
      demos {
        id
        title
        description
        image {
          id
          url
        }
        demoUrl {
          id
          url
        }
        active
        online
        associationId
      }
    }
  }
`;

export const updateEnterprise = /* GraphQL */ `
  mutation UpdateEnterprise($input: updateEnterpriseInput) {
    updateEnterprise(input: $input) {
      id
      title
      demos {
        id
        title
        description
        image {
          id
          url
        }
        demoUrl {
          id
          url
        }
        active
        online
        associationId
      }
    }
  }
`;

export const associateDemoToEnterprise = /* GraphQL */ `
  mutation AssociateDemoToEnterprise($input: associateDemoToEnterpriseInput) {
    associateDemoToEnterprise(input: $input)
  }
`;
export const removeDemoToEnterpriseAssociation = /* GraphQL */ `
  mutation RemoveDemoToEnterpriseAssociation(
    $input: removeDemoToEnterpriseAssociationInput
  ) {
    removeDemoToEnterpriseAssociation(input: $input)
  }
`;

export const createDemo = /* GraphQL */ `
  mutation CreateDemo($input: createDemoInput) {
    createDemo(input: $input) {
      id
      title
      description
      image {
        id
        url
      }
      demoUrl {
        id
        url
      }
      active
      online
      associationId
    }
  }
`;

export const deleteDemo = /* GraphQL */ `
  mutation DeleteDemo($input: deleteDemoInput) {
    deleteDemo(input: $input) {
      id
      title
      description
      image {
        id
        url
      }
      demoUrl {
        id
        url
      }
      active
      online
      associationId
    }
  }
`;
export const updateDemo = /* GraphQL */ `
  mutation UpdateDemo($input: updateDemoInput) {
    updateDemo(input: $input) {
      id
      title
      description
      image {
        id
        url 
      }
      demoUrl {
        id
        url
      }
      active
      online
      associationId
    }
  }
`;

export const createBoModule = /* GraphQL */ `
  mutation CreateBoModule(
    $input: CreateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    createBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBoModule = /* GraphQL */ `
  mutation UpdateBoModule(
    $input: UpdateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    updateBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBoModule = /* GraphQL */ `
  mutation DeleteBoModule(
    $input: DeleteBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    deleteBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUsersEnterpriseAssociation = /* GraphQL */ `
  mutation CreateUsersEnterpriseAssociation(
    $input: CreateUsersEnterpriseAssociationInput!
    $condition: ModelUsersEnterpriseAssociationConditionInput
  ) {
    createUsersEnterpriseAssociation(input: $input, condition: $condition) {
      id
      usergroup
      publicationId
      createdAt
      updatedAt
    }
  }
`;
export const updateUsersEnterpriseAssociation = /* GraphQL */ `
  mutation UpdateUsersEnterpriseAssociation(
    $input: UpdateUsersEnterpriseAssociationInput!
    $condition: ModelUsersEnterpriseAssociationConditionInput
  ) {
    updateUsersEnterpriseAssociation(input: $input, condition: $condition) {
      id
      usergroup
      publicationId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsersEnterpriseAssociation = /* GraphQL */ `
  mutation DeleteUsersEnterpriseAssociation(
    $input: DeleteUsersEnterpriseAssociationInput!
    $condition: ModelUsersEnterpriseAssociationConditionInput
  ) {
    deleteUsersEnterpriseAssociation(input: $input, condition: $condition) {
      id
      usergroup
      publicationId
      createdAt
      updatedAt
    }
  }
`;
