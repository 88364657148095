const Styles = theme => ({
    root: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(242,242,242, 0.8)',
        zIndex: 2000
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '7px 0px 20px 0px',
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '0px 7px 0px 7px',
        alignItems: 'center',
        ...theme?.typography?.title_h1,
        fontSize: '25px',
        fontWeight: '200',
        '& p': {
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    container: {
        flex: 1,
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme?.breakpoints?.between('0', '600')]: {
            padding: '20px',
        }
    },
    paper: {
        minWidth: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        padding: '25px',
        boxShadow: '1px 1px 10px #EEEEEE',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        [theme?.breakpoints?.between('0', '600')]: {
            padding: '10px'
        }
    }
})

export default Styles;