import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PublicRoutes } from '../routes';

import { AuthContext } from '../context/auth/auth-context.js';

import Styles from './style';

const useStyles = makeStyles(Styles);

const PublicLayout = () => {

    const { isBo } = useContext(AuthContext);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <PublicRoutes isBo={isBo} />
        </div>
    );
}

export default PublicLayout;