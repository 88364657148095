import React, { useRef, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { Button, MenuItem, ListItemIcon, ClickAwayListener, Grow, Paper, Popper, MenuList, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Done';

import Styles from './style';
import Icons from './assets/icons';


const useStyles = makeStyles(Styles);

const languages = [
    { id: 'pt', label: 'Português', icon: Icons.pt_flag },
    { id: 'en', label: 'Inglês', icon: Icons.en_flag },
    { id: 'es', label: 'Espanhol', icon: Icons.es_flag },
    { id: 'fr', label: 'Francês', icon: Icons.fr_flag },
]

const LanguageSelector = ({ type, actions }) => {

    const classes = useStyles();
    const anchorRef = useRef();
    const { t, i18n } = useTranslation();
    const currentLanguage = languages.find(language => language.id === i18n.language);
    const [inputLanguage, setInputLanguage] = useState(languages.find(language => language.id === 'pt'));
    const [open, setOpen] = useState(false);
    const [langs, setLangs] = useState();

    useEffect(() => {
        loadSupportedLangs();
    }, []);

    const loadSupportedLangs = () => {
        let supportedLangs = languages.filter(language => i18n.options.supportedLngs.includes(language.id));
        setLangs(supportedLangs);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleClickLanguage = (language) => {
        if (type === "app") {
            const lng = language.id;
            localStorage.setItem('lng', lng);
            i18next.changeLanguage(lng);
            setInputLanguage(languages.find(language => language.id === lng))
        } else if (type === "input") {
            actions.onLanguageChangeCallback(language.id);
            setInputLanguage(language)
        }
        setOpen(false);
    }

    return (
        <>
            {
                type === "app" ?
                    <div className={classes.root}>

                        <Tooltip title={t('language-selector-header')}>
                            <Button
                                disableRipple
                                ref={anchorRef}
                                className={classes.button}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                startIcon={<img alt="flag" style={{ width: '20px', height: '16px' }} src={currentLanguage.icon} />}
                                endIcon={<ExpandMore alt="arrow" style={{ width: '25px', margin: '-10px', color: '#000000', transform: open ? 'rotateX(180deg)' : 'rotateX(0deg)', transition: 'all 0.2s linear' }} src={Icons.drop_arrow} />}
                                onClick={handleToggle}
                                classes={{
                                    root: classes.size
                                }}
                            />
                        </Tooltip>

                        <Popper
                            className={classes.pooper}
                            placement="bottom-end"
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }} >
                                    <Paper elevation={0} className={classes.paper}>
                                        <div className={classes.arrowUp} />
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="menu-list-grow" autoFocusItem={open} className={classes.menuList}>
                                                <div className={classes.langHeader}>
                                                    {t('language-selector-header')}
                                                </div>
                                                {langs.map((language) => (
                                                    <MenuItem
                                                        key={language.id}
                                                        className={classes.menuItem}
                                                        onClick={() => handleClickLanguage(language)}>
                                                        <ListItemIcon style={{ minWidth: 'auto', marginRight: '8px', width: '20px', height: '16px' }}>
                                                            {<img alt="flag" src={language.icon} />}
                                                        </ListItemIcon>
                                                        <span>{language.id}</span>
                                                        {t(language.id)}
                                                        {currentLanguage.id === language.id && <CheckIcon style={{ marginLeft: 'auto' }} />}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                    :
                    <div className={classes.root}>
                        <Tooltip title={t('language-selector-header')}>
                            <Button
                                ref={anchorRef}
                                className={classes.button}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                startIcon={<img alt="flag" style={{ width: '25px' }} src={inputLanguage.icon} />}
                                endIcon={<ExpandMore alt="arrow" style={{ width: '25px', margin: '-10px', transform: open ? 'rotateX(180deg)' : 'rotateX(0deg)', transition: 'all 0.2s linear' }} src={Icons.drop_arrow} />}
                                onClick={handleToggle} >{inputLanguage.label}</Button>
                        </Tooltip>

                        <Popper
                            className={classes.pooper}
                            placement="bottom-end"
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }} >
                                    <Paper elevation={0} className={classes.paper}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList id="menu-list-grow" autoFocusItem={open} className={classes.menuList}>
                                                {langs.map((language) => (
                                                    <MenuItem
                                                        key={language.id}
                                                        className={classes.menuItem}
                                                        onClick={() => handleClickLanguage(language)}>
                                                        <ListItemIcon>
                                                            {<img alt="flag" style={{ width: '25px' }} src={language.icon} />}
                                                        </ListItemIcon>
                                                        {t(language.id)}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
            }
        </>
    );
}

export default LanguageSelector;