import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { TextFields } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePopupOverlay } from '../../../../components/popup-overlay';

import { ServiceContext } from '../../../../context/services/service-context';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ConfirmationDeleteRender = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { close, callback } = usePopupOverlay();
    const { Quiz } = useContext(ServiceContext);

    /**
    * Form submition handler when updated a Gallery category.
    */
    const onDeleteHandler = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            try {
                await callback();
                close();
            } catch (error) {
                throw error
            }
        },
    });

    return (
        <form onSubmit={onDeleteHandler.handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item sm>
                    <div className={classes.columnContainer}>
                        <div className={classes.description}>{props?.description}</div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.actions}>
                <Button type='submit' variant="contained" color="primary" disableElevation disabled={onDeleteHandler.isSubmitting} classes={{ root: classes.button }}>{
                    onDeleteHandler.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('enterprise-popup-delete-btn-continue')
                }</Button>
                <Button onClick={close} variant="outlined" color="secondary" disableElevation disabled={onDeleteHandler.isSubmitting} classes={{ root: classes.button }}>{t('enterprise-popup-delete-btn-cancel')}</Button>
            </div>
        </form>
    )
}

export default ConfirmationDeleteRender;