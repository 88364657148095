import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, FormControl, InputLabel, Select, CircularProgress } from '@material-ui/core';
import { TextFields, Accessible, Language, Link, PhoneRounded, AlternateEmailRounded, LocationOnRounded } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePopupOverlay } from '../../../../popup-overlay';

import { AppendArrayHandler } from '../../../../../utils/functions';
import { ServiceContext } from '../../../../../context/services/service-context';

import * as Config from '../../config';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const UploadFiles = (props) => {

    const classes = useStyles();
    const Context = useContext(ServiceContext);
    const { t } = useTranslation();
    const { close, callback } = usePopupOverlay();

    const [input, setInput] = useState({ file: null, thumbnail: null });

    const submitAsset = useFormik({
        initialValues: {
            id: props.data?.id,
            title: props.data?.title,
            altText: props.data?.altText,
            type: props.data?.type,
            url: props.data?.url,
        },
        onSubmit: async (values) => {
            try {
                let source = Config.allTypes.find(file => file.value == values.type).source;
                switch (source) {
                    case 'file':
                        let uploadUrl = await getUploadUrlRequest(values, input);
                        values.file = uploadUrl.data.getAssetUploadUrl;
                        await uploadFileHandler(values.file, input.file)

                        if (Boolean(input.thumbnail)) {
                            let uploadUrlThumbnail = await getThumbnailUploadUrlRequest(values, input);
                            values.thumbnail = uploadUrlThumbnail.data.getAssetThumbnailUploadUrl;
                            await uploadFileHandler(values.thumbnail, input.thumbnail)
                        }

                        if (Boolean(props.data)) {
                            await uploadAssetRequest(values);
                        }

                        await callback({ ...values, input: input });
                        close();

                        break;
                    case 'string':
                        if (Boolean(props.data)) {
                            await uploadAssetRequest(values);
                        }
                        callback({ ...values });
                        close();
                        break;
                }
            } catch (error) {
                throw error
            }
        },
    });

    const onFileTypeChange = (event) => {
        submitAsset.setFieldValue('type', event.target.value);
        setInput({ file: null, thumbnail: null });
    }

    const onInputFileChange = (event) => {
        const splitName = event.target.files[0].name.split('.');
        const extension = splitName[splitName.length - 1];
        const fileName = splitName[0];
        const file = {
            name: event.target.files[0].name,
            type: event.target.files[0].type,
            size: event.target.files[0].size,
            fileName: fileName,
            extension: extension,
            source: event.target.files[0],
        }
        if (event.target.id == "file") {
            setInput({ ...input, file: file });
        } else if (event.target.id == "thumbnail") {
            setInput({ ...input, thumbnail: file });
        }
    }

    const getUploadUrlRequest = async (data, input) => {
        const params = {
            asset: {
                title: data.title,
                type: data.type,
                altText: data.altText,
                fileName: input.file.fileName,
                extension: input.file.extension,
            }
        }
        return await Context['Assets'].getUploadUrl(params);
    }

    const getThumbnailUploadUrlRequest = async (data, input) => {
        const params = {
            thumbnail: {
                assetId: data.file.assetId,
                extension: input.thumbnail.extension
            }
        }
        return await Context['Assets'].getAssetThumbnailUploadUrl(params);
    }

    const uploadFileHandler = async (data, input) => {
        const params = {
            url: data.url,
            source: input.source,
            type: data.type
        }
        return await Context['Assets'].uploadFile(params);
    }

    const uploadAssetRequest = async (data) => {
        let params = {};
        let context = props.context;
        let module = Config.contextMethods(props.context);

        params[`${module.property}`] = {
            altText: data?.altText,
            assetId: data.file?.assetId,
            publicationComponentId: data?.id,
            type: data?.type,
            title: data?.title,
            thumbnail: data?.thumbnail?.bucketKey,
            url: data?.url,
        }

        let result = await Context[context][`${module.create}`](params);
        return result;
    }

    const displayFileTypes = () => {
        let types = [{ id: 'default', label: '', value: '' }]
        if (props.types) {
            types = AppendArrayHandler(types, Config.allTypes.filter(type => props.types.includes(type.value)));
            return types;
        } else {
            return Config.allTypes;
        }
    }

    const getAcceptedFile = (type) => {
        let accept = Config.allTypes.find(item => item.value === type).accept;
        return accept;
    }

    const onCancelUpload = () => {
        props.actions.onCancel(false);
    }

    return (
        <form onSubmit={submitAsset.handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
                <Grid item sm>
                    <div className={classes.columnContainer}>
                        <div className={classes.column}>
                            <TextField
                                id="title"
                                type='text'
                                label={t('component-uploadfile-upload-input-title-label')}
                                placeholder={t('component-uploadfile-upload-input-title-label')}
                                variant="outlined"
                                InputProps={{ startAdornment: <TextFields className={classes.icons} /> }}
                                onChange={submitAsset.handleChange}
                                defaultValue={submitAsset.values.title}
                                required
                            />
                            <TextField
                                id="altText"
                                type='text'
                                label={t('component-uploadfile-upload-input-altText-label')}
                                placeholder={t('component-uploadfile-upload-input-altText-label')}
                                variant="outlined"
                                InputProps={{ startAdornment: <Accessible className={classes.icons} /> }}
                                onChange={submitAsset.handleChange}
                                defaultValue={submitAsset.values.altText}
                            />
                            <FormControl variant="outlined" className={classes.dropdown}>
                                <InputLabel htmlFor="asset-type-select">{t('component-uploadfile-upload-input-type-label')}</InputLabel>
                                <Select
                                    id="type"
                                    onChange={onFileTypeChange}
                                    label={t('component-uploadfile-upload-input-type-label')}
                                    placeholder={t('component-uploadfile-upload-input-type-label')}
                                    inputProps={{ name: 'type', id: 'asset-type-select', }}
                                    classes={{ select: classes.dropdownSelect }}
                                    defaultValue={submitAsset.values.type}
                                    required
                                    native>
                                    {
                                        displayFileTypes().map((prop) => <option key={prop.id} value={prop.value}>{t(prop.label)}</option>)
                                    }
                                </Select>
                            </FormControl>
                            {
                                submitAsset.values.type == "IMAGE" || submitAsset.values.type == "MAP" ?
                                    <div style={{ width: '100%', margin: '10px 0px' }}>
                                        <label>Source: </label>
                                        <br />
                                        <br />
                                        <input id="file" type="file" accept={getAcceptedFile(submitAsset.values.type)} onChange={onInputFileChange} required />  
                                    </div>
                                    : null
                            }
                            {
                                submitAsset.values.type == "VIDEO" || submitAsset.values.type == "AUDIO" || submitAsset.values.type == "DOCUMENT" || submitAsset.values.type == "THREEDIMENSIONALOBJECT" ?
                                    <>
                                        <div style={{ width: '100%', margin: '10px 0px' }}>
                                            <label>Source: </label>
                                            <br />
                                            <br />
                                            <input id="file" type="file" accept={getAcceptedFile(submitAsset.values.type)} onChange={onInputFileChange} required />
                                        </div>
                                        <br />
                                        <div style={{ width: '100%', margin: '10px 0px' }}>
                                            <label>Thumbnail:</label>
                                            <br />
                                            <br />
                                            <input id="thumbnail" type="file" accept="image/*" onChange={onInputFileChange} />
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                submitAsset.values.type == "APPLINK" ?
                                    <TextField
                                        id="url"
                                        type='text'
                                        label={t('component-uploadfile-upload-input-applink-label')}
                                        placeholder={t('component-uploadfile-upload-input-applink-label')}
                                        variant="outlined"
                                        InputProps={{ startAdornment: <Link className={classes.icons} /> }}
                                        onChange={submitAsset.handleChange}
                                        defaultValue={submitAsset.initialValues.url}
                                        required
                                    />
                                    : null
                            }
                            {
                                submitAsset.values.type == "URL" ?
                                    <TextField
                                        id="url"
                                        type='text'
                                        label={t('component-uploadfile-upload-input-url-label')}
                                        placeholder={t('component-uploadfile-upload-input-url-label')}
                                        variant="outlined"
                                        InputProps={{ startAdornment: <Language className={classes.icons} /> }}
                                        onChange={submitAsset.handleChange}
                                        defaultValue={submitAsset.initialValues.url}
                                        required
                                    />
                                    : null
                            }
                            {
                                submitAsset.values.type == "PHONE" ?
                                    <TextField
                                        id="url"
                                        type='number'
                                        label={t('component-uploadfile-upload-input-phone-label')}
                                        placeholder={t('component-uploadfile-upload-input-phone-label')}
                                        variant="outlined"
                                        InputProps={{ startAdornment: <PhoneRounded className={classes.icons} /> }}
                                        onChange={submitAsset.handleChange}
                                        defaultValue={submitAsset.initialValues.url}
                                        required
                                    />
                                    : null
                            }
                            {
                                submitAsset.values.type == "EMAIL" ?
                                    <TextField
                                        id="url"
                                        type='email'
                                        label={t('component-uploadfile-upload-input-email-label')}
                                        placeholder={t('component-uploadfile-upload-input-email-label')}
                                        variant="outlined"
                                        InputProps={{ startAdornment: <AlternateEmailRounded className={classes.icons} /> }}
                                        onChange={submitAsset.handleChange}
                                        defaultValue={submitAsset.initialValues.url}
                                        required
                                    />
                                    : null
                            }
                            {
                                submitAsset.values.type == "LOCATION" ?
                                    <TextField
                                        id="url"
                                        type='text'
                                        label={t('component-uploadfile-upload-input-location-label')}
                                        placeholder={t('component-uploadfile-upload-input-location-label')}
                                        variant="outlined"
                                        InputProps={{ startAdornment: <LocationOnRounded className={classes.icons} /> }}
                                        onChange={submitAsset.handleChange}
                                        defaultValue={submitAsset.initialValues.url}
                                        required
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.actions}>
                <Button type='submit' variant="contained" disableElevation disabled={submitAsset.isSubmitting} classes={{ root: classes.button }}>{
                    submitAsset.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('component-uploadfile-upload-btn-add-label')
                }</Button>
                <Button onClick={onCancelUpload} variant="contained" disableElevation disabled={submitAsset.isSubmitting} classes={{ root: classes.button }}>{t('component-uploadfile-upload-btn-cancel-label')}</Button>
            </div>
        </form>
    )
}

export default UploadFiles;