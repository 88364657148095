const Styles = theme => ({
    root: {
        height: "35px", 
        margin: '10px', 
        display: 'flex', 
        position: 'relative',
        justifyContent: 'center', 
        alignItems: 'center',
        borderRadius: "35px", 
        overflow: 'hidden',  
        backgroundColor: '#B0191A', 
        '& p': {
            color: "#ffffff"
        }
    },
    icon: {
        margin: '0px 7px ', 
        padding: '2px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    action: {
        width: "20px", 
        height: "20px", 
        margin: '0px 9px', 
        borderRadius: "20px", 
        backgroundColor: "#ffffff",
        cursor: 'pointer'
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
    iconsInvert: {
        fontSize: '19px',
        color: '#ffffff',
    },
    iconTag: {
        fontSize: '20px',
        color: '#FFFFFF',
    },
    progress: {
        color: '#B0191A'
    },
    overlay: {
        width: '100%',
        height: '100%',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: 'rgba(242,242,242, 0.8)',
    }
})

export default Styles;