import React, {useState} from 'react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import * as Utils from '../../../utils/functions';


const CardDemo = ({
    classes,
    data
}) => {

    const { i18n, t } = useTranslation();
    
    const [mouseOver, setMouseOver] = useState(false)

    const onClickHandle = () => {
        if (data.online) {
            window.open(data?.demoUrl?.url, '_blank');
        }
       
    }

    
    return (
        <div className={classes.root} onClick={onClickHandle}>
            <div className={classes.header}  onMouseEnter={() => setMouseOver(true)}  onMouseLeave={() => setMouseOver(false)}>
                <div className="header-inner">
                    <h2>{Utils.getTextLanguage(data?.title, i18n.language)}</h2>
                    {/*<h3></h3>*/}
                </div>
            </div>
            <div className={classes.imageBox} onMouseEnter={() => setMouseOver(true)}  onMouseLeave={() => setMouseOver(false)}>
                    {mouseOver ? (
                        <div className={classes.descriptionBox}>
                              {Utils.getTextLanguage(data?.description, i18n.language)}
                        </div>
                    ) : (
                         data?.imageUrl  ?  <img src={data?.imageUrl} alt="" /> : null 
                        
                    )}
            </div>
            <div className={clsx({ [classes.footer]: true, [classes.notActive]: !data.online })}>
                <span>{data.online ? t('card-demo-footer-button-title') : t('card-demo-footer-notActive')}</span>
            </div>
  
       </div>
    )
}



export default compose(
    withStyles(styles)
)(CardDemo)
