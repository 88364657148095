const Styles = theme => ({
    form: {
        width: '100%'
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: theme?.palette?.scheme?.accent?.main
    },
    progress: {
        color: theme?.palette?.scheme?.accent?.main
    },
    paper:{
        display: 'flex',
        flexDirection: 'column',
        padding: '15px'
    },
    button: {
        margin: '25px 0px 0px auto !important',
    }
})

export default Styles;