import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Zoom from 'react-reveal/Zoom';
import { makeStyles, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CardDemo from '../../components/cards/CardDemo'
import { ServiceContext } from '../../context/services/service-context';
import { AuthContext } from '../../context/auth/auth-context.js';
import * as S3Service from '../../service/s3'
import Styles from './style';
import Assets from '../../assets';
import CardPlaceholder from '../../components/cards/CardPlaceholder';


const useStyles = makeStyles(Styles);


const DemosView = () => {

    const { t } = useTranslation();
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { Demos } = useContext(ServiceContext);
    const [demos, setDemos] = useState([])
    const [loading, setLoading] = useState(true)



    useEffect(() => {

        const getDemosForEnterprise = async () => {
            setLoading(true)
            let data = []
            try {
                const params = {
                    enterprise: userGroup[0]
                }
                const _data = await Demos.GetDemosForEnterprise(params)
                const _demos = _data?.getDemosForEnterprise?.demos
                await Promise.all(
                    await _demos.map(async (demo, index) => {
                        const url = await S3Service.getFile(demo?.image?.url)
                        data[index] = {
                            ...demo,
                            imageUrl: url
                        }
                    })
                )
                setDemos(data)
                setLoading(false)
            } catch (error) {
                setDemos([])
                setLoading(false)
            }  
        }

        getDemosForEnterprise()
    }, [])



    if (loading) return null

    return (
        <div className={classes.root}>
            <Container className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.titleBox}>
                        <Typography variant="h1">
                            CY Demo
                        </Typography>
                    </div>
                     <div className={classes.bulletBox}>
                        <img src={Assets.Images.cy_bullets} alt="bullets" />
                    </div>
                </div>
                { /*
                    <div className={classes.descriptionBox}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean convallis posuere.
                        Suspendisse ac mi sed nisl varius tempor ut a mauris. Vivamus quis elit ut diam sollicitudin mollis.
                        Maecenas at ullamcorper elit. Nam condimentum auctor quam, nec gravida libero dapibus nec.
                    </div>
                 */}
               
                <div className={classes.content} >
                    {demos.map((demo) => <CardDemo data={demo} key={demo.id} />)}
                    <CardPlaceholder />
                </div>
                </Container>
                    <Container className={classes.footer} maxWidth="xl">
                    <span className={classes.cycText}>CYCLOID Technology and Consulting, Lda.</span>
                    <span className={classes.cycPrivacy}>© All rights reserved.&nbsp;<a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchor} target="_blank">{t('signin-footer-text-subtext-2')}</a></span>
                    <span className={classes.cycWebsite}>www.cycloid.pt</span>
                </Container>
        </div>
    )
}

export default withRouter(DemosView);

