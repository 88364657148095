/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:348bd3bd-90af-4b80-8c10-046033b12d4a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_n3fB0jRu2",
    "aws_user_pools_web_client_id": "btt90e261dr140o4obf5l02vg",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3bu7qfowojfdvinuxykfqxd7wq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "cyc-demos-media95802-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
