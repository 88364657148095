import React, { useState, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, FormControl, InputLabel, Select, CircularProgress } from '@material-ui/core';
import { Search, CloudUpload, Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { usePopupOverlay } from '../../../../popup-overlay';

import { AppendArrayHandler } from '../../../../../utils/functions';
import { ServiceContext } from '../../../../../context/services/service-context';

import AssetCard from '../../../Components/Cards';

import * as Config from '../../config';
import Styles from './style.js';

const useStyles = makeStyles(Styles);

const LocalFiles = (props) => {

    const classes = useStyles();
    const Context = useContext(ServiceContext);
    const { t } = useTranslation();
    const { close, callback } = usePopupOverlay();
    const [result, setResult] = useState();
    const [listStatus, setListStatus] = useState({ currentIndex: 0, haveMore: false, items: [] });

    useEffect(() => {
        searchAssets.handleSubmit()
    }, []);

    const searchAssets = useFormik({
        initialValues: {
            title: '',
            type: 'IMAGE'
        },
        onSubmit: async (values) => {
            try {
                setListStatus({ currentIndex: 0, haveMore: false, items: [] });
                let params = {
                    filter: values
                }
                let result = await Context['Assets'].getAssets(params);
                setResult(result.data.getAssets);
                renderResult(result.data.getAssets, true);
            } catch (error) {
                throw error
            }
        },
    });

    const renderResult = (data, newSearch = false) => {
        let index = 0;
        let length = 0;
        let current = newSearch ? 0 : listStatus.currentIndex
        let itemCards = [];

        if (current + props.resultLimit > data.length) {
            length = data.length;
        } else {
            length = current + props.resultLimit;
        }

        for (var i = current; i < length; i++) {
            itemCards.push(data[i]);
            index = i;
        }

        if (newSearch) {
            setListStatus({ currentIndex: index, haveMore: data.length - 1 > index, items: itemCards });
        } else {
            setListStatus({ currentIndex: index, haveMore: data.length - 1 > index, items: [...listStatus.items, ...itemCards] });
        }
    }

    const onShowMoreHandler = () => {
        renderResult(result);
    }

    const displayFileTypes = () => {
        let types = [{ id: 'default', label: '', value: '' }]
        if (props.types) {
            types = AppendArrayHandler(types, Config.onlyFileTypes.filter(type => props.types.includes(type.value)));
            return types;
        } else {
            return Config.allTypes;
        }
    }

    const uploadAssetRequest = async (data) => {
        let params = {};
        let context = props.context;
        let module = Config.contextMethods(props.context);

        params[`${module.property}`] = {
            ...data,
            publicationComponentId: props?.data?.id,
        }

        let result = await Context[context][`${module.create}`](params);
        return result;
    }

    const onFileTypeChange = (event) => {
        searchAssets.setFieldValue('type', event.target.value);
    }

    const onAssetSelected = async (data) => {
        if (props?.data) {
            await uploadAssetRequest(data);
        }
        await callback(data);
        close();
    }

    const onUploadViewHandler = () => {
        props.actions.onUplaodView(true);
    }

    return (
        <form onSubmit={searchAssets.handleSubmit} className={classes.form}>
            <div className={classes.header}>
                <TextField
                    id="title"
                    type='text'
                    label={t('component-uploadfile-local-input-title-label')}
                    placeholder={t('component-uploadfile-local-input-title-label')}
                    variant="outlined"
                    InputProps={{ startAdornment: <Search className={classes.icons} /> }}
                    onChange={searchAssets.handleChange}
                    defaultValue={searchAssets.values.search}
                    disabled={searchAssets.isSubmitting}
                />
                <FormControl variant="outlined" className={classes.dropdown}>
                    <InputLabel htmlFor="asset-type-select">{t('component-uploadfile-local-input-type-label')}</InputLabel>
                    <Select
                        id="type"
                        onChange={onFileTypeChange}
                        label={t('component-uploadfile-local-input-type-label')}
                        placeholder={t('component-uploadfile-local-input-type-label')}
                        inputProps={{ name: 'type', id: 'asset-type-select', }}
                        classes={{ select: classes.dropdownSelect }}
                        defaultValue={searchAssets.values.type}
                        disabled={searchAssets.isSubmitting}
                        required
                        native>
                        {
                            displayFileTypes().map((prop) => <option key={prop.id} value={prop.value}>{t(prop.label)}</option>)
                        }
                    </Select>
                </FormControl>
                <div className={classes.row}>
                    <Button type='submit' variant="contained" disableElevation disabled={searchAssets.isSubmitting} classes={{ root: classes.button }}>{
                        searchAssets.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : <Search />
                    }</Button>
                    <Button onClick={onUploadViewHandler} variant="contained" disableElevation disabled={searchAssets.isSubmitting} classes={{ root: classes.button }}><CloudUpload /></Button>
                </div>
            </div>

            <div className={classes.container}>
                <div className={classes.results}>
                    {
                            listStatus.items.length > 0 ?
                                listStatus.items.map((prop, index) => {
                                    return (
                                        <AssetCard key={`asset-card-${index}`} data={{ content: prop, result: 'FILTERED' }} onClick={onAssetSelected} />
                                    )
                                })
                                :
                                <div className={classes.progressContainer}>
                                    <span className={classes.label} style={{ justifyContent: 'center' }}>{searchAssets.isSubmitting ? t('component-uploadfile-local-search-message') : t('component-uploadfile-local-empty-message')}</span>
                                </div>
                        }
                    {
                        listStatus.haveMore ?
                            <p onClick={onShowMoreHandler} className={classes.anchor}><span><Add className={classes.icons} /></span>{t('component-uploadfile-local-more-message')}</p>
                            : null
                    }
                </div>
            </div>

            <div className={classes.actions}>
                <Button onClick={close} variant="contained" disableElevation disabled={searchAssets.isSubmitting} classes={{ root: classes.button }}>{t('component-uploadfile-local-btn-close-label')}</Button>
            </div>
        </form>
    )
}

export default LocalFiles;